/**
 * ========================================================
 * Description: Renders the LHS nav menu (populates using a
 *              list of app_objects requested from the server)
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/
var SideBarMenuComponent = React.createClass({
    getInitialState: function () {
        var context = this;
        setTimeout(function() {
            context.get_menu_items();
        }, 0);


        /*
         //TODO: implement get_recent_app_objects using web sockets (server-side and client-side).

         this.get_recent_app_objects();
         var context = this;
         idle_interval = setInterval(function () {
         context.get_recent_app_objects();
         }, 3000);*/

        return {menu_items: [], subMenuKeys: []};
    },
    componentDidUpdate: function(){

         var sb = $('.page-sidebar');
         var height_sb = $("ul.sidebar-menu").height() + 50;
         var height_ds = $( window ).height() - 45;
         if(height_ds >height_sb ){
         sb.css('min-height',height_ds);
         }else{
         sb.css('min-height',height_sb);
         }
    },
    componentWillReceiveProps: function (nextProps) {
        if (nextProps.isCompact != this.props.isCompact) {
            if (!$('#sidebar').is(':visible'))
                $("#sidebar").toggleClass("hide");

            if ($(".sidebar-menu").closest("div").hasClass("slimScrollDiv")) {
                $(".sidebar-menu").slimScroll({destroy: true});
                $(".sidebar-menu").attr('style', '');
            }
            if (nextProps.isCompact) {
                $(".open > .submenu")
                    .removeClass("open");
            } else {
                if ($('.page-sidebar').hasClass('sidebar-fixed')) {
                    var position = (readCookie("rtl-support") || location.pathname == "/index-rtl-fa.html" || location.pathname == "/index-rtl-ar.html") ? 'right' : 'left';
                    $('.sidebar-menu').slimscroll({
                        height: 'auto',
                        position: position,
                        size: '3px',
                        color: themeprimary
                    });
                }
            }
        }
    },
    get_menu_items: function () {
        var request = {};
        request.entity = "app_object";
        request.method = "get_menu_items";

        var context = this;
        if (localStorage.getItem(request.method) === null || localStorage.getItem(request.method) =="" || localStorage.getItem(request.method) === undefined) {
            do_authenticated_http_call({
                method: "POST",
                url: "/api/entity/invoke_method",
                contentType: "application/json",
                dataType: 'json',
                data: JSON.stringify(request),
                success: function (data) {
                    localStorage.setItem(request.method, JSON.stringify(data.data));
                    var menu_items = [];
                    for (var module_key in data.data) {
                        menu_items.push(data.data[module_key]);
                    }
                    menu_items.sort(function (a, b) {
                        if (a.priority < b.priority) {
                            return -1;
                        }
                        if (a.priority == b.priority) {
                            return 0;
                        }
                        return 1;
                    });
                    context.setState({menu_items: menu_items});
                },
                error: function (error) {
                    if (error.responseJSON) {

                        console.log(error.responseJSON);
                        context.setState({error: error.responseJSON.message});
                        server_error_action(error);
                    }
                },
                complete: function () {
                }
            });
        }
        else{
            var data = localStorage.getItem(request.method);
            data = JSON.parse(data);
            var menu_items = [];
            for (var module_key in data) {
                menu_items.push(data[module_key]);
            }
            menu_items.sort(function (a, b) {
                if (a.priority < b.priority) {
                    return -1;
                }
                if (a.priority == b.priority) {
                    return 0;
                }
                return 1;
            });
            context.setState({menu_items: menu_items});

        }
    },
    collapse_menu_items: function() {
        this.setState({openMenuKey: null});
    },
    /*get_recent_app_objects: function () {
     //TODO: implement get_recent_app_objects using web sockets.
     var request = {};
     request.entity = "user";
     request.method = "get_recent_app_objects";

     var context = this;

     do_authenticated_http_call({
     method: "POST",
     url: "/api/entity/invoke_method",
     contentType: "application/json",
     dataType: 'json',
     data: JSON.stringify(request),
     success: function (data) {
     context.setState({recent_app_objects: data.data});
     },
     error: function (error) {
     if (error.responseJSON) {
     console.log(error.responseJSON);
     context.setState({error: error.responseJSON.message})
     }
     },
     complete: function () {
     }
     });
     },*/
    on_dashboard_click: function (event) {
        event.preventDefault();
        if(this.props.is_mobile) {
            this.props.compact_change_handler(true); //Mobile client's Home has a compact menu
            this.collapse_menu_items();
        }
        this.props.navigation_handler("home");
    },
    handle_app_object_click: function (app_object, workflow_state, event) {
        /*
        try{
            $('#loader').dxLoadPanel('hide');
        }catch(e){}
        try{
            $('#body-loader').dxLoadPanel('hide');
        }catch(e){}*/
        $('.dx-loadpanel-wrapper').remove();

        if (event && event.preventDefault) {
            event.preventDefault();
        }
        if (workflow_state) {
            if (workflow_state.work_queue_app_object_code) {
                app_object.type = "data_list";
                app_object.code = workflow_state.work_queue_app_object_code;
            }
            push_href_location(app_object.name + " - " + (R.client_application_title || "Xenforma"), "/data_list?code=" + app_object.code + "&workflow_status=" + workflow_state.status_code);
            app_object.workflow_conditions = {workflow_status: workflow_state.status_code};
        }
        else {
            push_href_location(app_object.name + " - " + (R.client_application_title || "Xenforma"), "/data_list?code=" + app_object.code);
            delete app_object.workflow_conditions;
        }

        this.props.app_object_handler(app_object);
    },
    on_open: function (menuKey, open) {
        if (open) {
            if(this.state.openMenuKey != menuKey) { //close all subMenus when switching modules
                this.setState({openMenuKey: menuKey, subMenuKeys: {}});
            }
            else {
                this.setState({openMenuKey: menuKey});
            }
        }
        else {
            this.setState({openMenuKey: null});
        }
    },
    on_sub_open: function (parentKey, menuKey, open) {
        var subMenuKeys = this.state.subMenuKeys;
        if (open) {
            subMenuKeys[parentKey] = menuKey;
        }
        else {
            subMenuKeys[parentKey] = undefined;
        }
        if(open && (this.state.openMenuKey != parentKey)) { //To ensure we don't have another module open (can happen when a module has a single member which was elevated)
            this.setState({openMenuKey: parentKey, subMenuKeys: subMenuKeys});
        }
        this.setState({subMenuKeys: subMenuKeys});
    },
    render: function () {
        var menu_items = [];

        for (var k = 0; k < this.state.menu_items.length; k++) {
            var module = this.state.menu_items[k];
            var module_name = module.name;

            var app_objects = [];
            var key = module.code;
            var app_object_element;

            for (var i = 0; i < module.app_objects.length; i++) {
                var app_object = module.app_objects[i];
                var workflows = [];
                if (app_object.workflow_states && app_object.workflow_states.length > 0) {
                    for (var j = 0; j < app_object.workflow_states.length; j++) {
                        var workflow_state = app_object.workflow_states[j];
                        var color = undefined;
                        if (workflow_state && workflow_state.view_options && workflow_state.view_options.class_color) {
                            color = workflow_state.view_options.class_color;
                        }
                        var workflow_li =
                            <SideBarMenuItemComponent
                                color={color}
                                title={color?workflow_state.caption.toUpperCase():workflow_state.caption}
                                onClick={this.handle_app_object_click.bind(this, app_object, workflow_state)}
                                key={workflow_state.status_code}/>;
                        workflows.push(workflow_li);
                    }
                }
                if (workflows.length > 0) {
                    var isSubOpen = this.state.subMenuKeys[key] == app_object.code;
                    app_object_element = <SideBarSection onOpen={this.on_sub_open.bind(this, key, app_object.code)}
                                                         icon={(module.app_objects.length == 1) ? module.font_awesome_icon : null}
                                                         onClick={this.handle_app_object_click.bind(this, app_object, undefined)}
                                                         isCompact={false}
                                                         title={app_object.name}
                                                         open={isSubOpen}
                                                         is_mobile={this.props.is_mobile}
                                                         key={app_object.code}
                    >{workflows}</SideBarSection>;
                }
                else {
                    app_object_element =
                        <SideBarMenuItemComponent title={app_object.name}
                                                  onClick={this.handle_app_object_click.bind(this, app_object, undefined)}
                                                  key={app_object.code}/>;
                }
                app_objects.push(app_object_element);
            }

            var menu_item;
            if(module.app_objects.length == 1) {
                menu_item = app_object_element;
            }
            else {
                var isOpen = this.state.openMenuKey && (this.state.openMenuKey == key);
                menu_item = <SideBarSection onOpen={this.on_open.bind(this, key)}
                                            isCompact={this.props.isCompact}
                                            compact_change_handler={this.props.compact_change_handler}
                                            title={module_name}
                                            open={isOpen}
                                            is_mobile={this.props.is_mobile}
                                            icon={module.font_awesome_icon}
                                            key={key}>{app_objects}</SideBarSection>;
            }
            menu_items.push(menu_item);
        }
        return (
            <ul className="nav sidebar-menu">
                <SideBarMenuItemComponent title={(this.props.is_mobile && this.props.isCompact) ? "" : (R.label_dashboard || "Home")} onClick={this.on_dashboard_click}
                                          icon="glyphicon glyphicon-home"/>
                {menu_items}
            </ul>)

    }
});

var SideBarMenuItemComponent = React.createClass({
    propTypes: {
        color: React.PropTypes.string,
        title: React.PropTypes.string,
        onClick: React.PropTypes.func,
        icon: React.PropTypes.string
    },
    getDefaultProps: function () {
        return {
            icon: null
        };
    },
    componentDidMount: function() {
        /*
        var element = $(ReactDOM.findDOMNode(this)).children('a');
        element.bind('click',null, false);
        element.on('click',this.props.onClick);*/
    },
    render: function () {
        var icon = null;
        if (this.props.icon != null) {
            icon = <i className={"menu-icon "+this.props.icon}>&nbsp;</i>
        }
        var borderColor = "";
        if (this.props.color) {
            borderColor = " label label-" + this.props.color;
        }
        var span;
        if(this.props.title) {
            span = <span className={"menu-text"+borderColor}> {this.props.title} </span>
        }
        else {
            span = "";
        }
        return (
            <li>
                <a href="" onClick={this.props.onClick}>
                    {icon}
                    {span}
                </a>
            </li>
        )
    }
});

var SideBarSection = React.createClass({
    getDefaultProps: function () {
        return {
            icon: null
        }
    },
    getInitialState: function () {
        return {};
    },
    componentDidMount: function(){
        /*
        var this_node = $(ReactDOM.findDOMNode(this));
        var element = this_node.children('a');
        element.bind('click', null, false);
        element = this_node.children(0);
        element.on('click', this.handleClick);
        /*
        element = this_node.children('a>i.menu-expand');
        element.bind('click', null, false);
        element.on('click', this.mouseOver);
        */
    },
    handleClick: function (event) {
        if (typeof this.props.onClick != "undefined") {
            this.props.onClick();
        }
        if (this.props.isCompact == true)
            return;
        if (this.props.open) {
            this.props.onOpen(false);
        } else {
            this.props.onOpen(true);
        }
    },
    mouseOver: function (event) {
        var context = this;
        if (context.props.isCompact == true) {
            return;
        }
        if (context.props.open) {
            context.props.onOpen(false);
        } else {
            context.props.onOpen(true);
        }
    },
    prevent_default_but_open_mobile_menu_for_browsing: function (event) {
        event.preventDefault();
        if(this.props.is_mobile) {
            if(this.props.compact_change_handler) {
                this.props.compact_change_handler(false); //Mobile client does not browse on a compact menu
                this.props.onOpen(true);
            }
        }
    },
    render: function () {
        var styles = {
            block: {
                display: "block"
            },
            none: {
                display: "none"
            }
        };
        var menuExpand = "";
        if (this.props.isCompact == false) {
            menuExpand = <i onClick={this.mouseOver} className="menu-expand">&nbsp;</i>;
        }
        var liClass = '';
        if (!this.props.isCompact && this.props.open) {
            liClass = 'open';
        }
        var icon = null;
        if (this.props.icon != null) {
            icon = <i className={"menu-icon fa " + this.props.icon}>&nbsp;</i>
        }
        return (
            <li className={liClass}>
                <a href="" onClick={this.prevent_default_but_open_mobile_menu_for_browsing} className="menu-dropdown">
                    {icon}
                    <div  onClick={this.handleClick} className="menu-text">
                        <span> {this.props.title}</span>
                    </div>
                    {menuExpand}
                </a>
                <ul className="submenu"
                    style={(!this.props.isCompact && this.props.open == true)?styles.block:styles.none}>
                    {this.props.children}
                </ul>
            </li>
        );
    }
});

var SideBarComponent = React.createClass({
    display_loader: function(){
        var context = this;
        if (!context.props.skip_init) context.props.handle_executing(true);
        setTimeout(function(){
            context.props.handle_executing(false);
        }, 10000);
    },
    getInitialState: function () {
        return {};
    },
    handle_search_submit: function (event) {
        $("#text_to_search").html(R.data_searching);

        this.state.loading = true;
        this.display_loader();

        /*setTimeout(function(){
        },1000);*/

        event.preventDefault();
        this.props.search_handler(this.state.search_string);


    },
    on_search_string_change: function (event) {
        /*Here!*/
        this.state.search_string = event.target.value;
    },
    componentDidMount: function(){
        $(window).on('resize', this.on_resize);
        this.on_resize();
    },
    componentWillUnmount: function(){
        $(this).off('resize');
    },
    on_resize: function(){
        var sb = $('.page-sidebar');
        sb.css('min-height',(window.innerHeight-sb.parent().position() || {}).top || 0);
    },
    render: function () {
        var pageSideBarClass = "page-sidebar";
        if (this.props.isCompact == true) {
            pageSideBarClass = pageSideBarClass + " menu-compact";
        }
        else if(this.props.is_mobile){
            pageSideBarClass = pageSideBarClass + " menu-full-width";
        }
        return (<div className={pageSideBarClass} id="sidebar">
            <div className="sidebar-header-wrapper">
                <form id="sidebar-form" onSubmit={this.handle_search_submit}>
                    <input  type="search" className="searchinput" style={{height:"39px", "paddingLeft":"38px"}} onChange={this.on_search_string_change}/>
                </form>
                <i className="searchicon fa fa-search"></i>
                <div className="searchhelper">{R.label_search_hint}</div>
            </div>
            <SideBarMenuComponent
                navigation_handler={this.props.navigation_handler}
                app_object_handler={this.props.app_object_handler}
                compact_change_handler={this.props.compact_change_handler}
                is_mobile={this.props.is_mobile}
                isCompact={this.props.isCompact}/>
        </div>);
    }
});
