/**
 * ========================================================
 * Description: web control for picking a date (no time)
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/

var EditorDevExtremeDatePicker = React.createClass({
    getInitialState: function () {
        return {value: this.props.value};
    },
    componentWillReceiveProps: function (nextProps) {
        if ((this.validDate !== false) || (nextProps.is_invalid)) {
            this.state.value = (nextProps.is_invalid) ? null : nextProps.value;
            this.state.skip_next_value_change_event = true;
            this.state.element.dxDateBox('instance').reset();
            this.state.skip_next_value_change_event = true;
            this.state.element.dxDateBox('instance').option("value", this.state.value);
            if(nextProps.is_invalid) {
                this.state.element.dxDateBox('instance').option("isValid", false);
            }
        }
        else if(nextProps.is_invalid === false) {
            this.state.element.dxDateBox('instance').option("isValid", true);
        }
    },
    componentDidMount: function () {
        this.state.element = $(this.domElement).dxDateBox(this.get_dx_date_constructor_configuration_object());
        $(this.domElement).find("input.dx-texteditor-input").inputmask({alias: "mm/dd/yyyy", yearrange: { minyear: 0, maxyear: 9999 }});
    },
    get_dx_date_constructor_configuration_object: function() {
        var context = this;
        var ret_val = {
            type: "date",
            readOnly: context.props.readOnly,
            value: (context.state.value == null) ? null : context.state.value,
            width: "auto",
            onKeyUp: function () {
                context.state.actualDateInputKeyboardValue = context.getDomInputElementValue();
                context.state.takeDateKeyboardValue = true;
            },
            onEnterKey: function () {
                context.state.skip_next_value_change_event = true;
                context.changeValue(true);
            },
            onFocusOut: function (e) {
                context.state.skip_next_value_change_event = true;
                context.changeValue(true);
                e.component.close(); //close the popup editor
                context.props.onBlur();
            },
            onValueChanged: function(e) {
                if(context.state.skip_next_value_change_event) {
                    context.state.skip_next_value_change_event = false;
                    return;
                }
                if(context.state.takeDateKeyboardValue) { //If they are using the keyboard then ignore because the onFocusOut event will fire when they are done

                }
                else {
                    context.runValueChangedEvent();
                }
            },
            onOpened: function() {
                var allDateCellsSelector = "div.dx-datebox-wrapper-date td.dx-calendar-cell";
                var currentDateCellSelector = "div.dx-datebox-wrapper-date td.dx-calendar-cell.dx-calendar-selected-date";
                $(currentDateCellSelector).on("click touchstart", function(e) {
                    $(allDateCellsSelector).off("click touchstart");
                    var run_value_changed_logic = context.state.takeDateKeyboardValue; //last input was from keyboard, need to reset value manually when this (currently selected) cell is clicked.
                    context.configureStateToUseMouseInput();
                    if(run_value_changed_logic) {
                        context.runValueChangedEvent(); //dx does not always run this event when the current value is re-selected
                    }
                });
                var nonCurrentdateCellSelector = "div.dx-datebox-wrapper-date td.dx-calendar-cell:not(.dx-calendar-selected-date)";
                $(nonCurrentdateCellSelector).on("click touchstart", function(e) {
                    $(allDateCellsSelector).off("click touchstart");
                    context.configureStateToUseMouseInput();
                });
            }
        };

        if(context.props.is_mobile) {
            ret_val.pickerType = "calendar";
        }

        return ret_val;
    },
    configureStateToUseMouseInput: function() {
        this.state.actualDateInputKeyboardValue = "";
        this.state.takeDateKeyboardValue = false;
    },
    runValueChangedEvent: function() {
        this.state.skip_next_value_change_event = true;
        this.changeValue(true);
        this.props.onBlur();
    },
    changeValue: function() {
        var formattedValueObject = this.formatDateBeforeChange();
        if ( !formattedValueObject.isValid ) {
            // Invalid date, we set it to void
            this.setDomInputElementValue("");
            this.validDate = false;
            this.props.onChange(null);
            this.state.lastDateSet = undefined;
            return;
        }
        // From here, the date is valid
        this.validDate = true;
        var formattedValue = formattedValueObject.value;

        var formattedDate = new Date(formattedValue);
        // If the date that was set is actually different from the old one
        if ( !this.state.lastDateSet || this.isDateDifferentFromPreviousOne(formattedDate) ) {
            this.state.lastDateSet = formattedDate;
            // we save the value
            this.props.onChange(formattedDate);
        } else { // If we have the same date finally, we just assign to the input the last date that was formatted
            this.state.skip_next_value_change_event = true;
            this.state.element.dxDateBox('instance').reset();
            this.state.skip_next_value_change_event = true;
            this.state.element.dxDateBox('instance').option('value', formattedDate);
        }
    },
    parse_date_info_from_string: function(value) {
        var nonValidObject = { isValid: false };
        if (!value || (value.indexOf("m") != -1) || (value.indexOf("d") != -1) || (value.indexOf("y") != -1)) { //didn't finish filling out the mask
            if(value && (value.length > 2) && (value.indexOf("yy") == (value.length - 2)) && (value.indexOf("yyy") == -1) && (value.indexOf("m") == -1) && (value.indexOf("d") == -1)) { //has a trailing yy:  may still be valid
                //try parsing this value without the trailing "yy"
                value = value.replace("yy", "");
            }
            else {
                return nonValidObject;
            }
        }
        // replacement of all the '/' with '-'
        value = value.replace(new RegExp("\/", 'g'), "-");

        var values = value.split("-");
        // we check if we have 3 values (month, day, year)
        if ( values.length === 3 ) {
            for ( var i=0 ; i<values.length ; i++ ) {
                var currentValue = values[i];
                // each value should be a integer
                if ( isNaN(parseInt(currentValue)) ) {
                    return nonValidObject;
                }
            }

            if(values[0] && (values[0].length != 4)) { //Already in format YYYY-MM-DD (Comes in this way on Mobiles)
                var lastSeparatorIndex = value.lastIndexOf("-");
                var dayMonth = value.substr(0, (lastSeparatorIndex + 1));
                var year = value.substr(lastSeparatorIndex + 1);
                if (year.length < 3 && !isNaN(parseInt(year))) {
                    // here fixing the date with the year typed with only one or two digits
                    // e.g.: '01' -> 2001; '12' -> '2012'.
                    if (year.length === 1) year = "200" + year;
                    if (year.length === 2) year = "20" + year;
                    value = dayMonth + year;
                }
                // replacement of all the '-' with '/'
                value = value.replace(new RegExp("-", 'g'), "\/");
            }

            var date = moment(new Date(value));
            var isMomentValid = date.isValid();

            return {
                isValid: isMomentValid,
                // format date including UTC if it's valid
                value: isMomentValid ? date.format("YYYY-MM-DDTHH:mm:ss.SSSZ") : ""
            };
        }

        return nonValidObject;
    },
    getDomInputElementValue: function() {
        var element = $(this.domElement).find('input');
        return element[0].value;
    },
    setDomInputElementValue: function(elementValue) {
        var element = $(this.domElement).find('input');
        element[0].value = elementValue;
    },
    isDateDifferentFromPreviousOne: function(newDate) {
        return this.state.lastDateSet && this.state.lastDateSet instanceof Date && newDate && newDate instanceof Date && this.state.lastDateSet.getTime() !== newDate.getTime();
    },
    formatDateBeforeChange: function() {
        var value = this.state.takeDateKeyboardValue ? this.state.actualDateInputKeyboardValue : this.getDomInputElementValue();
        if (this.state.takeDateKeyboardValue && general_utils.string_is_empty_or_whitespace(value)) {
            value = this.getDomInputElementValue();
        }
        return this.parse_date_info_from_string(value);
    },
    render: function () {
        var context = this;
        return <div style={{width:"auto"}} ref={function(ref){context.domElement = ref}}>&nbsp;</div>;
    }
});
