/**
 * ============================================================
 * Description:     nested data list view component
 * Creation Date:   12/29/16
 * Author:          Anthony C
 * ============================================================
 */

var ReferenceLookupDataListComponent = React.createClass({
    debugging: false,
    tab: 0,
    debugLog: function (msg, start) {
        if (this.debugging) {
            if (!start) {
                this.tab--;
            }
            try {
                console.log((start ? '-> ' : '<- ') + new Array(this.tab + 1).join('\t') + msg + this.tab);
            } catch (e) {
                console.log((start ? '-> ' : '<- ') + msg + this.tab);
            }
            if (start) {
                this.tab++;
            }
        }
    },
    propTypes: {
        app_object_code: React.PropTypes.string,
        app_object_conditions: React.PropTypes.object,
        app_object_handler: React.PropTypes.func,
        disable_create: React.PropTypes.bool,
        disallow_grid_filters: React.PropTypes.bool,
        disallow_grid_workflows: React.PropTypes.bool,
        grid_init_callback: React.PropTypes.func,
        handle_add: React.PropTypes.func,
        handle_delete: React.PropTypes.func,
        handle_executing: React.PropTypes.func,
        is_mobile: React.PropTypes.bool,
        is_open: React.PropTypes.bool,
        logged_in_false: React.PropTypes.func,
        nested_entity_handler: React.PropTypes.func,
        nested_reference_entity_data: React.PropTypes.object,
        new_entity_json: React.PropTypes.object,
        parent_react_entity: React.PropTypes.object,
        record_count: React.PropTypes.any,
        set_open_state: React.PropTypes.func
    },

    /**
     * Returns the custom data grid properties for the grid to be correctly initialized
     * @param args
     * @returns {*}
     */
    get_custom_grid_properties: function (args) {
        this.debugLog("get_custom_grid_properties", true);
        this.debugLog("get_custom_grid_properties", false);

        return {
            force_ref_load: true,
            paging: { enabled: true },
            scrolling: { mode: "infinite", preloadEnabled: false, showScrollbar: "always" },
            filterRow: { visible: !this.props.disallow_grid_filters },
            searchPanel: { visible: !this.props.disallow_grid_filters },
            export: { enabled: false },
            columnChooser: { enabled: false }
        };
    },

    render: function () {
        var context = this;

        context.debugLog("render", true);
        context.debugLog("render", false);

        return (
            <DataListComponent
                               app_object_code={context.props.app_object_code}
                               app_object_conditions={context.props.app_object_conditions}
                               app_object_handler={context.props.app_object_handler}
                               disable_create={context.props.disable_create}
                               disallow_grid_filters={context.props.disallow_grid_filters}
                               disallow_grid_workflows={context.props.disallow_grid_workflows}
                               get_custom_grid_properties={context.get_custom_grid_properties}
                               grid_init_callback={context.props.grid_init_callback}
                               grid_is_on_edit_form={true}
                               handle_add={context.props.handle_add}
                               handle_delete={context.props.handle_delete}
                               handle_executing={context.props.handle_executing}
                               is_mobile={context.props.is_mobile}
                               is_open={context.props.is_open}
                               is_ref_lookup={true}
                               logged_in_false={context.props.logged_in_false}
                               nested_entity_handler={context.nested_entity_handler}
                               nested_reference_entity_data={context.props.nested_reference_entity_data}
                               new_entity_json={context.props.new_entity_json}
                               parent_react_entity={context.props.parent_react_entity}
                               record_count={context.props.record_count}
                               set_open_state={context.props.set_open_state}
            />
        );
    }
});
