"use strict";
/**
 ==================================================================================
 Description:       Calendar View DevExtreme
 Creation Date:     6/24/2016
 Author:            Victor Hernandez
 ==================================================================================
 */

var EditorDevExtremeCalendarView = React.createClass({
    propTypes: {
        dataSource: React.PropTypes.object,
        resources: React.PropTypes.array,
        attributes: React.PropTypes.object,
        onAppointmentClick: React.PropTypes.func,
        getHeight: React.PropTypes.func,
        grid_id: React.PropTypes.string,
        initDataSource: React.PropTypes.func,
        readOnly: React.PropTypes.string,
        buildResources: React.PropTypes.func
    },
    getInitialState: function () {
        var context = this;

        if (!context.props.resources){
            context.props.buildResources();
        }

        return {
            resources: context.props.resources,
            dataSource: context.props.dataSource,
            descriptionExpr: context.props.descriptionExpr,
            endDateExpr: context.props.endDateExpr,
            startDateExpr: context.props.startDateExpr
        };
    },
    componentWillReceiveProps: function (nextProps) {
        var context = this;

        if ( context.state.element ) {
            try{
                if ( nextProps.dataSource != context.state.dataSource ) {
                    context.state.dataSource = nextProps.dataSource;
                    context.state.element.option('dataSource', context.state.dataSource);
                }
                if ( nextProps.descriptionExpr != context.state.descriptionExpr ) {
                    context.state.descriptionExpr = nextProps.descriptionExpr;
                    context.state.element.option('descriptionExpr', context.state.descriptionExpr);
                }
                if ( nextProps.endDateExpr != context.state.endDateExpr ) {
                    context.state.endDateExpr = nextProps.endDateExpr;
                    context.state.element.option('endDateExpr', context.state.endDateExpr);
                }
                if ( nextProps.startDateExpr != context.state.startDateExpr ) {
                    context.state.startDateExpr = nextProps.startDateExpr;
                    context.state.element.option('startDateExpr', context.state.startDateExpr);
                }
            } catch(e){}
        }
    },
    componentDidMount: function () {
        var context = this;

        context.state.dataSource = context.props.dataSource;
        context.state.dataSource.on('changed', function(){
            var items = this.items(),
                new_resources = [],
                current_resources = context.props.resources[0].dataSource;

            for ( var i = 0, u = current_resources.length; i < u; i++ ) {
                new_resources.push(current_resources[i].id);
            }

            for ( i = 0, u = items.length; i<u; i++ ) {
                var currentItem = items[i];
                var resources = currentItem.resources;
                for ( var j = 0, v = resources.length; j<v; j++ ) {
                    if (new_resources.indexOf(resources[j]) == -1){
                        new_resources.push(resources[j]);
                    }
                }
            }

            for ( j=0,v=current_resources.length; j<v; j++ ) {
                for ( i=0, u=new_resources.length; i<u; i++ ) {
                    if ( !new_resources[i].id && new_resources[i].indexOf(current_resources[j].id)!=-1 ) {
                        new_resources[i] = {
                            color:current_resources[j].color,
                            id:new_resources[i],
                            text:current_resources[j].text
                        };
                    } else if ( 'string' == typeof new_resources[i] && new_resources[i].indexOf('none')!=-1 ) {
                        new_resources[i] = {
                            color:'teal',
                            id:new_resources[i],
                            text:'None'
                        }
                    }
                }
            }

            context.state.resources[0].dataSource = new_resources;
        });

        var schedulerConfig = {
            readOnly: context.props.readOnly,
            dataSource: context.state.dataSource,
            groups: [],
            //endDateExpr: this.props.endDateExpr,
            //startDateExpr: this.props.startDateExpr,
            resources: context.state.resources,
            onAppointmentClick: context.props.onAppointmentClick,
            //onContentReady: this.calendar_contrast_fix,
            views:['month', 'week'],
            currentView: 'month',
            currentDate: new Date(),
            appointmentTemplate: context.appointmentTemplate,
            appointmentPopupTemplate: context.appointmentPopupTemplate,
            appointmentTooltipTemplate: context.appointmentTooltipTemplate,
            height: context.props.getHeight,
            editing:{
                allowAdding:false,
                allowDeleting:false,
                allowDragging:false,
                allowResizing:false,
                allowUpdating:true
            }
        };

        context.state.element = $(context.domElement).dxScheduler(schedulerConfig)
        context.state.element = context.state.element.dxScheduler('instance');
        context.state.element.showAppointmentPopup = function(e){
            $('.dx-scheduler-dropdown-appointments[aria-expanded="true"]').click();
            context.props.onAppointmentClick(e);
        }
    },

    appointmentTemplate: function(appointmentInfo, appointmentIndex, appointmentElement){
        var elt = find_by_field('id', appointmentInfo.resources[0], this.state.element.option('resources')[0].dataSource);
        appointmentElement.addClass('label-' + elt.color);
        return 'item';
    },
    appointmentPopupTemplate: function(){
        return 'appointmentPopup';
    },
    appointmentTooltipTemplate: function(){
        return 'appointmentTooltip';
    },

    /**
     * Returns the calendar view instance
     * @returns {*}
     */
    getCalendarViewInstance: function () {
        return this.state.element;
    },

    render: function () {
        var context = this;

        var className = "";
        if ( context.props.add_button ) {
            className = "add-button-margin";
        }

        return (
            <input class="form-control"/>,
            <div id={context.props.grid_id} className={className} ref={function(ref){context.domElement = ref}}>&nbsp;</div>
        );
    }
});
