/**
 * ============================================================
 * Description:     nested data list view component
 * Creation Date:   12/29/16
 * Author:          Anthony C
 * ============================================================
 */

var NestedDataListComponent = React.createClass({
    debugging: false,
    tab: 0,
    debugLog: function (msg, start) {
        if (this.debugging) {
            if (!start) {
                this.tab--;
            }
            try {
                console.log((start ? '-> ' : '<- ') + new Array(this.tab + 1).join('\t') + msg + this.tab);
            } catch (e) {
                console.log((start ? '-> ' : '<- ') + msg + this.tab);
            }
            if (start) {
                this.tab++;
            }
        }
    },
    propTypes: {
        app_object: React.PropTypes.object,
        app_object_handler: React.PropTypes.func,
        delete_nested_entity: React.PropTypes.func,
        entity_attributes: React.PropTypes.object,
        entity_instances: React.PropTypes.array,
        handle_executing: React.PropTypes.func,
        is_mobile: React.PropTypes.bool,
        is_open: React.PropTypes.bool,
        nested_entity_handler: React.PropTypes.func,
        nested_list_entities: React.PropTypes.object,
        on_change: React.PropTypes.func,
        parent_entity_array: React.PropTypes.array,
        parent_entity_field_attribute: React.PropTypes.object,
        parent_react_entity: React.PropTypes.object,
        read_only: React.PropTypes.bool,
        set_open_state: React.PropTypes.func
    },

    /**
     * Returns the custom data grid properties for the grid to be correctly initialized
     * @param args
     * @returns {*}
     */
    get_custom_grid_properties: function (args) {
        var context = this,
            custom_grid_properties = {};

        custom_grid_properties.force_ref_load = false;
        custom_grid_properties.paging = { enabled: false };
        custom_grid_properties.scrolling = { mode: "infinite", preloadEnabled: false, showScrollbar: "always" };
        custom_grid_properties.filterRow = { visible: false };
        custom_grid_properties.searchPanel = { visible: false };
        custom_grid_properties.export = { enabled: false };
        custom_grid_properties.columnChooser = { enabled: false };

        return custom_grid_properties;
    },

    render: function () {
        var context = this;

        return (
            <DataListComponent app_object={context.props.app_object}
                               app_object_handler={context.props.app_object_handler}
                               delete_nested_entity={context.props.delete_nested_entity}
                               entity_attributes={context.props.entity_attributes}
                               entity_instances={context.props.entity_instances}
                               get_custom_grid_properties={context.get_custom_grid_properties}
                               grid_is_on_edit_form={true}
                               handle_executing={context.props.handle_executing}
                               is_mobile={context.props.is_mobile}
                               is_nested={true}
                               is_open={context.props.is_open}
                               nested_entity_handler={context.props.nested_entity_handler}
                               nested_list_entities={context.props.nested_list_entities}
                               on_change={context.props.on_change}
                               parent_entity_array={context.props.parent_entity_array}
                               parent_entity_field_attribute={context.props.parent_entity_field_attribute}
                               parent_react_entity={context.props.parent_react_entity}
                               read_only={context.props.read_only}
                               set_open_state={context.props.set_open_state} />
        );
    }
});
