/**
 * ========================================================
 * Description: Component to render a server error to the page
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/
var EditFormComment = React.createClass({
    propTypes: {
        title: React.PropTypes.string,
        comment: React.PropTypes.string,
        handleSaveClick: React.PropTypes.func,
        handleCancelClick: React.PropTypes.func
    },
    getInitialState: function () {
        return {};
    },
    handleCancelClick: function(e)
    {
        e.preventDefault();
        this.props.handleCancelClick();
    },
    handleSaveClick: function(e)
    {
        e.preventDefault();
        this.props.handleSaveClick($(this.refs.comment).val());
    },
    componentDidMount: function()
    {
        $(this.refs.comment).val(this.props.comment);
    },
    render: function () {
        return (
        <div id="comment-box">
            <div className="row" >
                <div className="form-group">
                    <div className="col-sm-12 form-group" style={{textAlign:"center"}}>
                        <label className="col-sm-12" >{this.props.title}</label>
                        <textarea ref="comment"
                                  rows="10"
                                  style={{width:"97%", marginTop:"10px"}}
                                  placeholder={R.please_enter_comment}/>
                    </div>
                </div>
            </div>

            <div id="workflow-button-box"
                 align="right"
                 className="pull-right"
                 style={{
                            width: "100%",
                            height : "auto" ,
                            padding: "10px",
                            backgroundColor : "white",
                            boxShadow: "1px 0 10px 1px rgba(0,0,0,0.3)",
                            textAlign: "right",
                            position:"absolute",
                            left:"0px",
                            bottom:"-50px"
                            }}>
                <div style={{display : "inlineTable", width: "auto"}}>
                    <button className="btn btn-success shiny workflow_button_margin"
                            onClick={this.handleSaveClick}>{R.label_save}</button>
                    <button onClick={this.handleCancelClick}
                            className="btn btn-default shiny">{R.label_cancel}</button>
                </div>
            </div>
        </div>
        );
    }
});
