"use strict";
/**
 ==================================================================================
 Description:       desc
 Creation Date:     6/8/2016
 Author:            SebasWebcreek
 ==================================================================================
 */

var EditorDevExtremeAutoComplete = React.createClass({
    getInitialState: function () {
        return {};
    },
    componentWillReceiveProps: function (nextProps) {
        var element = this.state.element.dxAutocomplete('instance');
        if (nextProps.value != this.state.value) {
            this.state.value = nextProps.value;
            element.option("value", this.state.value);
        } else {
            //element.repaint();
        }
    },
    componentDidMount: function () {
        var context = this;
        var template = function (itemData) {
            return '<div>' +
                itemData.caption + '</div>';
        };
        this.state.value = this.props.value;
        this.state.element = $(this.domElement).dxAutocomplete({
            readOnly: this.props.readOnly,
            id: this.props.id,
            value: this.state.value,
            displayValue: this.props.displayExpr,
            valueExpr: this.props.valueExpr,
            dataSource: this.props.dataSource,
            focusStateEnabled:true,
            itemTemplate : template,
            minSearchLength :1,
            onContentReady : autosize_dropdown_list_width_by_options,
            onValueChanged: function (data) {
                context.state.value = data.value;
                if(context.state.value == null) {
                    context.props.onChange(null);
                } else {
                    context.props.onChange(data.value);
                }
            },
            onFocusOut: function () {
                if((context.state.last_blur != null) && (context.state.last_blur == context.state.value)) {
                    return; //Prevents the onEnterKey logic from running alongside this logic
                }
                if (context.props.dataSource && context.props.dataSource.items) {
                    var items = context.props.dataSource.items();
                    var autocomplete_fields = (first_where(items, function (a) {
                        return (a && (a.entity_id == context.state.value));
                    }) || {}).autocomplete_fields;
                    context.props.set_fields(autocomplete_fields);
                }
                context.state.last_blur = null;
                context.props.onBlur();
            },
            onEnterKey: function (e) {
                if(context.props.dataSource && context.props.dataSource.items) {
                    var items = context.props.dataSource.items();
                    if ((items.length == 1) || (!first_where(items, function(a) {
                            return (a && (a.entity_id == context.state.value));
                        }))) {
                        var element = context.state.element.dxAutocomplete('instance');
                        element.option('value', items[0].entity_id);
                    }
                    var autocomplete_fields = (first_where(items, function(a) {
                        return (a && (a.entity_id == context.state.value));
                    }) || {}).autocomplete_fields;
                    context.props.set_fields(autocomplete_fields);
                    e.component.option("opened", false);
                    if(context.props.set_next_focus_element) {
                        context.props.set_next_focus_element();
                    }
                }
                context.state.last_blur = context.state.value;
                context.props.onBlur();
            }
        });
        if(this.props.id) {
            $(this.domElement).find("input").attr("id", this.props.id);
        }
    },
    render: function () {
        var context = this;
        var className = "";
        return <input class="form-control"/>,<div className={className} ref={function(ref){context.domElement = ref}}>&nbsp;</div>;
    }
});
