/**
 * ============================================================
 * Description:     default data list view component (Usually the one displayed when browsing through the menu)
 * Creation Date:   12/29/16
 * Author:          Anthony C
 * ============================================================
 */

var DefaultDataListComponent = React.createClass({
    debugging: false,
    tab: 0,
    debugLog: function (msg, start) {
        if (this.debugging) {
            if (!start) {
                this.tab--;
            }
            try {
                console.log((start ? '-> ' : '<- ') + Array(this.tab + 1).join('\t') + msg + this.tab);
            } catch (e) {
                console.log((start ? '-> ' : '<- ') + msg + this.tab);
            }
            if (start) {
                this.tab++;
            }
        }
    },
    propTypes: {
        app_object_code: React.PropTypes.string,
        app_object_conditions: React.PropTypes.object,
        app_object_handler: React.PropTypes.func,
        drilldown_conditions_holder: React.PropTypes.object,
        entity_attributes: React.PropTypes.object,
        entity_instances: React.PropTypes.array,
        handle_executing: React.PropTypes.func,
        init_notes_data_grid: React.PropTypes.func,
        is_mobile: React.PropTypes.bool,
        logged_in_false: React.PropTypes.func,
        navigation_handler: React.PropTypes.func,
        skip_location_push: React.PropTypes.bool
    },

    /**
     * Returns the custom data grid properties for the grid to be correctly initialized
     * @param args
     * @returns {*}
     */
    get_custom_grid_properties: function (args) {
        var context = this;

        return {
            force_ref_load: false,
            paging: { enabled: true },
            scrolling: { mode: "infinite", preloadEnabled: false, showScrollbar: "always" },
            filterRow: { visible: !context.props.is_mobile },
            searchPanel: { visible: true },
            export: { enabled: true },
            height: context.init_grid_height(),
            columnChooser: {
                enabled: true,
                mode: "select",
                title: R.label_column_chooser || "Column Chooser",
                height: 260,
                width: 250,
                emptyPanelText: R.label_empty_column_chooser || "Please drag columns into here to remove them from the grid"
            }
        };
    },

    /**
     * Returns the initial height of the data grid component
     * @returns {*}
     */
    init_grid_height: function () {
        var context = this,
            grid_height;
        context.debugLog('init_grid_height', true);

        var description_grid = $(".description_grid");
        if ( description_grid.height() == null ) {
            grid_height = $(window).height() - 180;
        } else {
            var heightDescription = description_grid.height();
            if ( description_grid.css("display") == "none" ) {
                heightDescription = 0;
            }

            grid_height = $(window).height() - 195 - heightDescription;
        }

        context.debugLog('init_grid_height', false);
        return grid_height;
    },

    render: function () {
        var context = this;

        return (
            <DataListComponent app_object_code={context.props.app_object_code}
                               app_object_conditions={context.props.app_object_conditions}
                               app_object_handler={context.props.app_object_handler}
                               drilldown_conditions_holder={context.props.drilldown_conditions_holder}
                               get_custom_grid_properties={context.get_custom_grid_properties}
                               handle_executing={context.props.handle_executing}
                               is_mobile={context.props.is_mobile}
                               logged_in_false={context.props.logged_in_false}
                               navigation_handler={context.props.navigation_handler}
                               skip_location_push={context.props.skip_location_push} />
        );
    }
});
