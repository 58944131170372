/**
 * ========================================================
 * Description: Reference Lookup Container
 * Creation Date: 04/07/17
 * Author: Anthony C
 * ========================================================
 **/

var RefLookupContainerComponent = React.createClass({
    propTypes: {
        attribute: React.PropTypes.object,
        attribute_is_read_only: React.PropTypes.any,
        attribute_key_name: React.PropTypes.string,
        attribute_value: React.PropTypes.any,
        edit_form_context: React.PropTypes.object,
        entity_instance: React.PropTypes.object,
        nested_entity_name: React.PropTypes.any,
        set_next_focus_element_function: React.PropTypes.any,
    },

    debugging: false,
    tab: 0,
    debugLog: function (msg, start){
        var context = this;
        if (this.debugging){
            if (!start) {
                this.tab--;
            }
            try{
                console.log((start?'-> ':'<- ') + Array(this.tab+1).join('\t') + msg + this.tab);
            } catch(e){console.log((start?'-> ':'<- ') + e + this.tab);}
            if (start) {
                this.tab++;
            }
        }
    },


    ///////////////
    // LIFECYCLE //
    ///////////////

    componentWillMount: function () {
        this.debugLog("componentWillMount", true);

        var context = this,
            state = {};

        var edit_form_context = context.props.edit_form_context,
            attribute = context.props.attribute,
            attribute_is_read_only = context.props.attribute_is_read_only,
            entity_instance = context.props.entity_instance,
            attribute_key_name = context.props.attribute_key_name;

        edit_form_context
            .variant_ref_add_button(attribute)
            .then(function(att) {
                if (att && !att.hide_var_ref_buttons) {
                    // create choose entity popover
                    state.choose_entity_popover = context.get_entity_popover(att);

                    // create add button
                    state.add_button = context.get_add_button(att);

                    // create edit button
                    state.edit_button = context.get_edit_button(att);

                    context.setState(state);
                }

                context.debugLog("componentWillMount", false);
            })
            .catch(function (error) {
                context.debugLog("componentWillMount", false);
            })
        ;
    },

    getInitialState: function() {
        this.debugLog("getInitialState", true);
        this.debugLog("getInitialState", false);

        // initial state is empty
        return {};
    },


    /////////////
    // METHODS //
    /////////////

    /**
     * Creates choose entity popover
     * @param att
     */
    get_entity_popover: function(att) {
        this.debugLog('get_entity_popover', true);

        const context = this,
              attribute_key_name = this.props.attribute_key_name;

        // if attribute contains choose entity elements
        if (att.choose_entity) {
            var options_variant = [];

            for (var j = 0; j < att.choose_entity.length; j++) {
                var lov = att.choose_entity[j];
                var option_variant = (
                    <option key={lov.entity_name} data-html="true" value={lov.entity_name}>
                        {lov.entity_name_caption}
                    </option>
                );

                options_variant.push(option_variant);
            }

            context.debugLog('get_entity_popover', false);

            // create choose entity popover (select)
            return (
                <div key={attribute_key_name+"saasd"}
                    style={{"position":"absolute","left":"-9999px","visibility":"hidden"}}>
                    <div className="col-xs-12" id={"id"+(att.safe_field_path || att.field_path)+"variant"}>
                        <div style={{"textAlign":"center","marginBottom":"10px"}}>
                            {R.variant_ref_caption? R.variant_ref_caption:"What would you like to create?"}
                        </div>
                        <div className="form-group">
                            <select
                                className="form-control"
                                id={"variantref_"+(att.safe_field_path || att.field_path)}>
                                {options_variant}
                            </select>
                        </div>
                    </div>
                </div>
            );
        }

        this.debugLog('get_entity_popover', false);

        return undefined;
    },

    /**
     * Creates button to open choose entity popover
     * @param att
     */
    get_add_button: function(att) {
        this.debugLog('get_add_button', true);

        const context = this,
              attribute_is_read_only = this.props.attribute_is_read_only,
              edit_form_context = this.props.edit_form_context;

        if (!attribute_is_read_only) {
            // add button function
            var add_func = function(att, e) {
                if (e) e.preventDefault();

                if (edit_form_context.state.entity_attributes.field_paths[att.field_path].choose_entity) {
                    var id_tag = (att.safe_field_path || att.field_path) + "variant";

                    var open_popover_callback = function() {
                        try {
                            var variantRefSelector = "#variantref_" + (att.safe_field_path || att.field_path);

                            $(variantRefSelector).each(function() {
                                var element = $(this);

                                var button_style = 'margin: 5px; float: right; margin-left: 5px: margin-top: 5px; margin-bottom: 10px';
                                var button_box = $('<div/>', {style:"padding:5px"}),
                                    save_button_var = $(
                                        '<button/>',
                                        {
                                            type: 'button',
                                            style: button_style,
                                            class: 'btn btn-success shiny workflow_button_margin',
                                            id: 'button_choose_report_popover',
                                            text: R.client_label_ok ? R.client_label_ok : "Ok",
                                            click: function() {
                                                var selected_value = $(variantRefSelector).val();

                                                edit_form_context.setState({
                                                    flagSaveDropdown: 1,
                                                    currentFieldPath: att.field_path,
                                                    currentAttribute: att,
                                                    chosen_entity: selected_value
                                                });

                                                if (selected_value) {
                                                    edit_form_context.state.popover.hide();
                                                    edit_form_context.reference_entity_handler(att, null, selected_value);
                                                }
                                            }
                                        }
                                    ),
                                    cancel_button_var = $('<button/>', {
                                        type: 'button',
                                        style: 'margin:5px; float:right; margin-left:5px: margin-top:5px;margin-bottom:10px',
                                        class: 'btn shiny btn-default',
                                        id: 'button_cancel_assigned_to_user',
                                        text: R.label_cancel,
                                        click: function() { edit_form_context.state.popover.hide(); }
                                    })
                                ;

                                cancel_button_var.appendTo(button_box);
                                save_button_var.appendTo(button_box);
                                $(button_box).appendTo($(element).parents()[0]);
                            });
                        } catch(error){}
                    };

                    edit_form_context.open_popover(id_tag, open_popover_callback);
                }
                else if (edit_form_context.state.entity_attributes.field_paths[att.field_path].var_ref_entity) {
                    var chosen_entity = edit_form_context.state.entity_attributes.field_paths[att.field_path].var_ref_entity[0].entity_name;

                    edit_form_context.setState({
                        flagSaveDropdown: 1,
                        currentFieldPath: att.field_path,
                        currentAttribute: att,
                        chosen_entity: chosen_entity
                    });

                    if (chosen_entity) {
                        edit_form_context.reference_entity_handler(att, null, chosen_entity);
                    }
                }
            };

            context.debugLog('get_add_button', false);

            // create add button
            return (
                <button
                    style={{"marginTop": "1px"}}
                    type="button"
                    id={(att.safe_field_path || att.field_path) + "variant"}
                    className="edit-controls-add-button add-button dx-button"
                    onClick={add_func.bind(edit_form_context, att)}
                    tabIndex={-1}
                >
                    <div className='dx-button-content'>
                        <i className='dx-icon dx-icon-edit-button-addrow fa fa-pencil-square-o'></i>
                    </div>
                </button>
            );
        }

        this.debugLog('get_add_button', false);

        return undefined;
    },

    /**
     * Creates button to edit selected value
     * @param att
     */
    get_edit_button: function(att) {
        this.debugLog('get_edit_button', true);

        const context = this,
              attribute_is_read_only = this.props.attribute_is_read_only,
              edit_form_context = this.props.edit_form_context,
              entity_instance = this.props.entity_instance;

        if (!attribute_is_read_only || att.allow_edit_from_read_only) {
            // edit button function
            var edit_func = function (att, e) {
                var current_field_value = get_json_data_using_field_paths(att.local_field_path || att.field_path, entity_instance);

                if (current_field_value == null ) {
                    if (e) e.preventDefault();
                } else {
                    var entity_id = current_field_value.entity_id || current_field_value;
                    var entity_name = current_field_value.entity_name;
                    edit_form_context.setState({
                        flagSaveDropdown: 1,
                        currentFieldPath: att.field_path,
                        currentAttribute: att
                    });

                    edit_form_context.variant_edit_function(att, entity_id, entity_name);
                }
            };

            this.debugLog('get_edit_button', false);

            // create edit button
            return (
                <button
                    type="button"
                    onClick={edit_func.bind(edit_form_context, att)}
                    className="02 edit-controls-edit-button edit-button dx-button"
                    style={{zIndex: "100"}}
                    tabIndex={-1}
                >
                    <div className='dx-button-content'>
                        <i className='dx-icon dx-icon-edit-button-addrow fa fa-pencil-square-o'></i>
                    </div>
                </button>
            );
        }

        this.debugLog('get_edit_button', false);

        return undefined;
    },

    get_variant_datasource: function() {
        return this.props.edit_form_context.variant_load(this.props.attribute);
    },

    has_add_button: function() {
        return !!this.state.add_button;
    },

    has_edit_button: function() {
        return !!this.state.add_button;
    },


    ////////////
    // RENDER //
    ////////////

    render: function() {
        this.debugLog("render", true);
        this.debugLog("render", false);

        const attribute = this.props.attribute,
              attribute_is_read_only = this.props.attribute_is_read_only,
              attribute_key_name = this.props.attribute_key_name,
              attribute_value = this.props.attribute_value,
              edit_form_context = this.props.edit_form_context,
              nested_entity_name = this.props.nested_entity_name,
              set_next_focus_element_function = this.props.set_next_focus_element_function;

        const placeholder = R.start_typing_to_search ? R.start_typing_to_search : 'Start typing to search...';

        return (
            <div
                key={attribute_key_name}
                className='ref_lookup_container'
            >
                <EditorDevExtremeLookup
                    dataSource={this.get_variant_datasource()}
                    displayExpr="caption"
                    valueExpr="entity_id"
                    currentInstance={edit_form_context[attribute.field_path+"array"]}
                    value={attribute_value || null}
                    skip_prop_update_function={(nested_entity_name && (nested_entity_name != ""))}
                    set_next_focus_element={set_next_focus_element_function}
                    onChange={edit_form_context.on_field_change.bind(edit_form_context, attribute)}
                    onBlur={edit_form_context.on_field_blur.bind(edit_form_context, attribute)}
                    add_button={this.has_add_button()}
                    edit_button={this.has_edit_button()}
                    readOnly={attribute_is_read_only}
                    placeholder={placeholder}
                />
                {this.state.add_button}
                {this.state.edit_button}
                {this.state.choose_entity_popover}
            </div>
        );
    }
});

