/**
 * ========================================================
 * Description: Modal component
 * Creation Date: 02/04/2016
 * Author: Vasil Galinovsky
 * ========================================================
 **/
var ModalComponent = React.createClass({
    componentWillMount: function () {
      $('html').css('overflow-y', 'hidden');
    },
    componentDidMount: function(){
        $(ReactDOM.findDOMNode(this)).on('show.bs.modal', function (e) {
            $(this).children('.modal-dialog').velocity('transition.fadeIn');
        });
        $(ReactDOM.findDOMNode(this)).modal('show');
        $(ReactDOM.findDOMNode(this)).on('hide.bs.modal', this.props.handleHideModal);
    },
    componentWillUnmount: function()
    {
        $('html').css('overflow-y', 'visible');
        $(ReactDOM.findDOMNode(this)).off('hide.bs.modal', this.props.handleHideModal);
        $(ReactDOM.findDOMNode(this)).modal('hide');
    },
    render:function()
    {
        return  <div id="modal_form" className="modal">
            <div className="modal-dialog modal-edit-form" >
                <div className="modal-content">
                        {
                            this.props.children
                        }
                </div>
            </div>
        </div>
    }
});
