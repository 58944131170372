/**
 * ========================================================
 * Description: Component to render a server error to the page
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/
var ErrorNotificationComponent = React.createClass({
    componentDidMount: function(){
        ReactDOM.findDOMNode(this).scrollIntoView();
    },
    render: function() {
        var message = this.props.message;

        if(message) {
            message = message.split("\n").map(function (i) {
                return <div>{i}</div>;
            });
        }
        return (
            <div id="error_notification" className="alert alert-danger fade in">
                <button type="button" className="close" onClick={this.props.on_close}>
                    ×
                </button>
                {message}
            </div>);
    }
});
