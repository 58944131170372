/**
 * ========================================================
 * Description: A web control for selecting a time (no date)
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/
var EditorDevExtremeTimePicker = React.createClass({
    getInitialState: function () {
        return {value: this.props.value};
    },
    componentWillReceiveProps: function (nextProps) {
        if ((this.validDate !== false) || (nextProps.is_invalid)) {
            this.state.value = (nextProps.is_invalid) ? null : nextProps.value;
            this.state.skip_next_value_change_event = true;
            this.state.element.dxDateBox('instance').reset();
            this.state.skip_next_value_change_event = true;
            this.state.element.dxDateBox('instance').option("value", this.state.value);
            if(nextProps.is_invalid) {
                this.state.element.dxDateBox('instance').option("isValid", false);
            }
        }
        else if(nextProps.is_invalid === false) {
            this.state.element.dxDateBox('instance').option("isValid", true);
        }
    },
    componentDidMount: function() {
        this.state.element = $(this.domElement).dxDateBox(this.get_dx_time_constructor_configuration_object());
        $(this.domElement).find("input.dx-texteditor-input").inputmask({alias: "hh:mm t"});
    },
    get_dx_time_constructor_configuration_object: function() {
        var context = this;
        var ret_val = {
            type: "time",
            readOnly: context.props.readOnly,
            value: (context.state.value == null) ? null : new Date(context.state.value),
            onKeyUp: function () {
                context.state.actualTimeInputKeyboardValue = context.getDomTimeInputElementValue();
                context.state.takeTimeKeyboardValue = true;
            },
            onEnterKey: function () {
                context.state.skip_next_value_change_event = true;
                context.changeValue(false);
            },
            onFocusOut: function (e) {
                context.state.skip_next_value_change_event = true;
                context.changeValue(false);
                e.component.close(); //close the popup editor
                context.props.onBlur();
            },
            onValueChanged: function() {
                if(context.state.skip_next_value_change_event) {
                    context.state.skip_next_value_change_event = false;
                    return;
                }
                if(context.state.takeTimeKeyboardValue) { //If they are using the keyboard then ignore because the onFocusOut event will fire when they are done

                }
                else {
                    context.state.skip_next_value_change_event = true;
                    context.changeValue(false);
                    context.props.onBlur();
                }
            },
            onOpened: function() {
                var timeSelector = "div.dx-datebox-wrapper-time > div";
                $(timeSelector).on("click touchstart", ".dx-list-item-content", function(e) {
                    context.state.actualTimeInputKeyboardValue = "";
                    context.state.takeTimeKeyboardValue = false;
                    $(timeSelector).off("click touchstart");
                });
            }
        };

        if(context.props.is_mobile) {
            ret_val.pickerType = "calendar";
        }

        return ret_val;
    },
    changeValue: function() {
        var formattedValueObject = this.formatTimeBeforeChange();
        if ( !formattedValueObject.isValid ) {
            // Invalid input, we set both date and time fields to void
            this.state.actualTimeInputKeyboardValue = "";
            this.setDomTimeInputElementValue("");
            this.validDate = false;
            this.props.onChange(null);
            this.state.lastDateSet = undefined;
            return;
        }
        // From here, the date is valid
        this.validDate = true;
        var formattedValue = formattedValueObject.value;

        var date = new Date();
        // setting the time properties from the values we formatted
        this.setTimePropertiesFromValue(date, formattedValueObject);
        if ( !this.state.formattedTimeValue || this.state.formattedTimeValue !== formattedValue ) {
            this.state.formattedTimeValue = formattedValue;
            // we save the value
            this.props.onChange(date);
        } else { // If we have the same date finally, we just assign to the input the last date that was formatted
            this.state.skip_next_value_change_event = true;
            this.state.element.dxDateBox('instance').reset();
            this.state.skip_next_value_change_event = true;
            this.state.element.dxDateBox('instance').option('value', date);
            if(date && !this.state.value) {
                this.props.onChange(date); //just to make sure
            }
        }
    },
    parse_time_info_from_string: function(value) {
        var nonValidObject = { isValid: false };
        if ( value && value.length > 0 ) {
            value = value.toUpperCase();
            var hours, minutes, isAM;
            if ( _.endsWith(value, "AM") || _.endsWith(value, "PM") || _.endsWith(value, "XM") ) { //XM is added because of the input mask we are using
                isAM = _.endsWith(value, "AM") || _.endsWith(value, "XM");
                value = _.trim(value.substr(0, value.length-2));
            }
            if ( value.indexOf(":") !== -1 ) {
                var values = value.split(":");
                hours = values[0];
                minutes = values[1];
                if ( hours.length > 2 || minutes.length > 2 ) {
                    return nonValidObject;
                }
            } else if ( value.length === 4 ) {
                hours = value.substr(0, 2);
                minutes = value.substr(2, 2);
            } else if (value.length === 3) {
                hours = value.substr(0, 1);
                minutes = value.substr(1, 2);
            } else {
                return nonValidObject;
            }
            var hoursInt = parseInt(hours);
            var minutesInt = parseInt(minutes);
            if ( isNaN(hoursInt) || isNaN(minutesInt) || hoursInt < 0 || hoursInt > 24 || minutesInt < 0 || minutesInt > 59 ) {
                return nonValidObject;
            }
            if ( hoursInt > 12 ) {
                if ( isAM === true ) {
                    return nonValidObject;
                }
            } else if ( isAM === false && hoursInt !== 12 ) {
                hoursInt += 12;
            } else if (hoursInt === 12 && isAM === true) {
                hoursInt = 0;
            }
            return {
                isValid: true,
                value: ("" + hoursInt + ":" + minutesInt),
                hours: hoursInt,
                minutes: minutesInt
            };
        }

        return nonValidObject;
    },
    getDomTimeInputElementValue: function() {
        return $(this.domElement).find('input[type="text"]').val();
    },
    setDomTimeInputElementValue: function(elementValue) {
        $(this.domElement).find('input[type="text"]').val(elementValue);
    },
    setTimePropertiesFromValue: function(date, timeValue) {
        date.setHours(timeValue.hours);
        date.setMinutes(timeValue.minutes);
        date.setSeconds(0);
        date.setMilliseconds(0);
    },
    formatTimeBeforeChange: function() {
        var value = _.trim(this.state.takeTimeKeyboardValue ? this.state.actualTimeInputKeyboardValue : this.getDomTimeInputElementValue());
        return this.parse_time_info_from_string(value);
    },
    render: function() {
        var context = this;
        return <div ref={function(ref){context.domElement = ref}} />;
    }
});
