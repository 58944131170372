/**
 * ========================================================
 * Description: Helper method for parsing a mongodb-format
 *              conditions object and seeing which
 *              JSON objects match that condition.
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/
//https://github.com/louischatriot/nedb
function matchCondition() {
    var comparisonFunctions = {};
    var logicalOperators = {};

    function areThingsEqual(a, b) {
        var aKeys, bKeys, i;

        // Strings, booleans, numbers, null
        if (a === null || typeof a === 'string' || typeof a === 'boolean' || typeof a === 'number' ||
            b === null || typeof b === 'string' || typeof b === 'boolean' || typeof b === 'number') {
            return a === b;
        }

        // Dates
        if (_.isDate(a) || _.isDate(b)) {
            return _.isDate(a) && _.isDate(b) && a.getTime() === b.getTime();
        }

        // Arrays (no match since arrays are used as a $in)
        // undefined (no match since they mean field doesn't exist and can't be serialized)
        if ((!(_.isArray(a) && _.isArray(b)) && (_.isArray(a) || _.isArray(b))) || a === undefined || b === undefined) {
            return false;
        }

        // General objects (check for deep equality)
        // a and b should be objects at this point
        try {
            aKeys = Object.keys(a);
            bKeys = Object.keys(b);
        } catch (e) {
            return false;
        }

        if (aKeys.length !== bKeys.length) {
            return false;
        }
        for (i = 0; i < aKeys.length; i += 1) {
            if (bKeys.indexOf(aKeys[i]) === -1) {
                return false;
            }
            if (!areThingsEqual(a[aKeys[i]], b[aKeys[i]])) {
                return false;
            }
        }
        return true;
    }

    function areComparable(a, b) {
        if (typeof a !== 'string' && typeof a !== 'number' && !_.isDate(a) &&
            typeof b !== 'string' && typeof b !== 'number' && !_.isDate(b)) {
            return false;
        }
        if (typeof a !== typeof b) {
            return false;
        }
        return true;
    }

    comparisonFunctions.$lt = function (a, b) {
        return areComparable(a, b) && a < b;
    };

    comparisonFunctions.$lte = function (a, b) {
        return areComparable(a, b) && a <= b;
    };

    comparisonFunctions.$gt = function (a, b) {
        return areComparable(a, b) && a > b;
    };

    comparisonFunctions.$gte = function (a, b) {
        return areComparable(a, b) && a >= b;
    };

    comparisonFunctions.$ne = function (a, b) {
        if (a === undefined) {
            return true;
        }
        return !areThingsEqual(a, b);
    };

    comparisonFunctions.$regex = function (a, b) {
        if (!_.isRegExp(b)) {
            throw new Error("$regex operator called with non regular expression");
        }

        if (typeof a !== 'string') {
            return false
        } else {
            return b.test(a);
        }
    };

    logicalOperators.$or = function (obj, query) {
        var i;

        if (!_.isArray(query)) {
            throw new Error("$or operator used without an array");
        }

        for (i = 0; i < query.length; i += 1) {
            if (match(obj, query[i])) {
                return true;
            }
        }

        return false;
    };

    logicalOperators.$and = function (obj, query) {
        var i;

        if (!_.isArray(query)) {
            throw new Error("$and operator used without an array");
        }

        for (i = 0; i < query.length; i += 1) {
            if (!match(obj, query[i])) {
                return false;
            }
        }

        return true;
    };

    logicalOperators.$not = function (obj, query) {
        return !match(obj, query);
    };

    function isPrimitiveType(obj) {
        return ( typeof obj === 'boolean' ||
        typeof obj === 'number' ||
        typeof obj === 'string' ||
        obj === null ||
        _.isDate(obj) ||
        _.isArray(obj));
    }

    function matchQueryPart(obj, queryKey, queryValue, treatObjAsValue) {
        var objValue = obj[queryKey]
            , i, keys, firstChars, dollarFirstChars;

        // queryValue is an actual object. Determine whether it contains comparison operators
        // or only normal fields. Mixed objects are not allowed
        if (queryValue !== null && typeof queryValue === 'object' && !_.isRegExp(queryValue) && !_.isArray(queryValue)) {
            keys = Object.keys(queryValue);
            firstChars = _.map(keys, function (item) {
                return item[0];
            });
            dollarFirstChars = _.filter(firstChars, function (c) {
                return c === '$';
            });

            if (dollarFirstChars.length !== 0 && dollarFirstChars.length !== firstChars.length) {
                throw new Error("You cannot mix operators and normal fields");
            }

            // queryValue is an object of this form: { $comparisonOperator1: value1, ... }
            if (dollarFirstChars.length > 0) {
                for (i = 0; i < keys.length; i += 1) {
                    if (!comparisonFunctions[keys[i]]) {
                        throw new Error("Unknown comparison function " + keys[i]);
                    }

                    if (!comparisonFunctions[keys[i]](objValue, queryValue[keys[i]])) {
                        return false;
                    }
                }
                return true;
            }
        }

        // Using regular expressions with basic querying
        if (_.isRegExp(queryValue)) {
            return comparisonFunctions.$regex(objValue, queryValue);
        }

        // queryValue is either a native value or a normal object
        // Basic matching is possible
        if (!areThingsEqual(objValue, queryValue)) {
            return false;
        }

        return true;
    }


    function matches_conditions(obj, query) {
        var queryKeys, queryKey, queryValue, i;
        queryKeys = Object.keys(query);
        for (i = 0; i < queryKeys.length; i += 1) {
            queryKey = queryKeys[i];
            queryValue = query[queryKey];
            if (queryKey[0] === '$') {
                if (!logicalOperators[queryKey]) {
                    throw new Error("Unknown logical operator " + queryKey);
                }
                if (!logicalOperators[queryKey](obj, queryValue)) {
                    return false;
                }
            } else {
                if (!matchQueryPart(obj, queryKey, queryValue)) {
                    return false;
                }
            }
        }
        return true;
    }

    this.match = function (obj, query) {
        var queryKeys, queryKey, queryValue, i;

        // Primitive query against a primitive type
        // This is a bit of a hack since we construct an object with an arbitrary key only to dereference it later
        // But I don't have time for a cleaner implementation now
        if (isPrimitiveType(obj) || isPrimitiveType(query)) {
            return matchQueryPart({needAKey: obj}, 'needAKey', query);
        }

        // Normal query
        queryKeys = Object.keys(query);
        for (i = 0; i < queryKeys.length; i += 1) {
            queryKey = queryKeys[i];
            queryValue = query[queryKey];

            if (queryKey[0] === '$') {
                if (!logicalOperators[queryKey]) {
                    throw new Error("Unknown logical operator " + queryKey);
                }
                if (!logicalOperators[queryKey](obj, queryValue)) {
                    return false;
                }
            } else {
                if (!matchQueryPart(obj, queryKey, queryValue)) {
                    return false;
                }
            }
        }

        return true;
    };
}
