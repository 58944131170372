/**
 * ============================================================
 * Description:     Calendar view component
 * Creation Date:   11/29/16
 * Author:          Anthony C
 * ============================================================
 **/

var CalendarViewComponent = React.createClass({
    debugging: false,
    tab: 0,
    debugLog: function (msg, start){
        if (this.debugging){
            if (!start) {
                this.tab--;
            }
            try{
                console.log((start?'-> ':'<- ') + Array(this.tab+1).join('\t') + msg + this.tab);
            } catch(e){console.log((start?'-> ':'<- ') + msg + this.tab);}
            if (start) {
                this.tab++;
            }
        }
    },
    propTypes: {
        app_object: React.PropTypes.object,
        apply_app_object_sorting: React.PropTypes.func,
        call_data_list_set_state: React.PropTypes.func,
        description_grid: React.PropTypes.object,
        entity_attributes: React.PropTypes.object,
        error_component: React.PropTypes.object,
        export_component: React.PropTypes.object,
        export_grid_conditions: React.PropTypes.object,
        grid_id: React.PropTypes.string,
        handle_executing: React.PropTypes.func,
        on_click_data_list_element: React.PropTypes.func,
        set_data_list_state: React.PropTypes.func,
        showBodyDatagrid: React.PropTypes.string,
        update_height: React.PropTypes.func
    },
    getInitialState: function() {
        var context = this;
        context.debugLog('getInitialState', true);

        context.debugLog('getInitialState', false);
        return {};
    },
    componentWillMount: function() {
        var context = this;
        context.debugLog('componentWillMount', true);

        // Initialization of the component, loading the data
        context.init_calendar(true);

        context.debugLog('componentWillMount', false);
    },
    componentWillReceiveProps: function(nextProps) {
        var context = this;
        context.debugLog('componentWillReceiveProps', true);

        // The component was updated, we re-load the data but not reinitializing the dataSource
        context.init_calendar(false);
        if ( context.calendar_data_source ) {
            context.calendar_data_source.load();
        }

        context.debugLog('componentWillReceiveProps', false);
    },

    init_calendar: function (shouldInitDataSource){
        var context = this;
        context.debugLog('init_calendar', true);

        if ( context.props.app_object && context.props.app_object.custom_properties ) {
            var custom_properties = context.props.app_object.custom_properties;
            var descriptionExpr = custom_properties.descriptionExpr;
            descriptionExpr = descriptionExpr ? descriptionExpr : 'doc_num';
            var startDateExpr = custom_properties.startDateExpr;
            startDateExpr = startDateExpr ? startDateExpr : 'contract_date';
            var endDateExpr = custom_properties.endDateExpr;
            endDateExpr = endDateExpr ? endDateExpr : 'expiration_date';

            if (shouldInitDataSource) context.init_calendar_datasource();
            context.setState({
                descriptionExpr: descriptionExpr,
                startDateExpr: startDateExpr,
                endDateExpr: endDateExpr,
                resources: context.build_resources()
            });

            context.debugLog('init_calendar', false);
            return true;
        }

        context.debugLog('init_calendar', false);
        return false;
    },
    init_calendar_datasource: function() {
        var context = this;
        context.debugLog('init_calendar_datasource', true);

        context.calendar_data_source = context.load_calendar_dataSource();

        context.debugLog('init_calendar_datasource', false);
    },
    load_calendar_dataSource: function () {
        var context = this;
        context.debugLog('load_calendar_dataSource', true);

        var fields = context.props.app_object.custom_properties.calendar_fields;
        if (!fields){
            fields = [];
            fields[0] = {
                end_datetime_field:'expiration_date',
                start_datetime_field:'contract_date',
                recurrence_field:'trans_schedule',
                resources_field:'workflow_status'
            }
        }
        else {
            for (var i = 0, u = fields.length; i < u; i++){
                fields[i].resources_field = fields[i].resources_field||'workflow_status'
            }
        }

        var send_data = {
                conditions: {},
                last_id: undefined
            },
            error_func = function (error) {
                context.debugLog('load_calendar_dataSource error_func', true);
                if ( error.responseJSON ) {
                    context.props.call_data_list_set_state({ error: error.responseJSON.message });
                } else if ( error.message ) {
                    context.props.call_data_list_set_state({ error: error.message });
                } else if ( error ) {
                    context.props.call_data_list_set_state({ error: error });
                }
                context.debugLog('load_calendar_dataSource error_func', false);
            },
            data_source = new DevExpress.data.DataSource({
                key: "_id", //this.state.primaryKey,
                load: function (load_options) {
                    context.debugLog('load_calendar_dataSource load', true);

                    var d = new $.Deferred();
                    var success_func = function (data) {
                        context.debugLog('load_calendar_dataSource load success_func', true);

                        fix_response_data(data, context.props.entity_attributes.field_paths, context.props.entity_attributes.nested_list_entities);

                        var retval = convert_results_to_calendar_fields(fields, data.entity_instances, context.props.entity_attributes, true);

                        //Separate elements by resource
                        var by_resource = {}, resources, resource, j, v;
                        for(i = 0, u = retval.length; i<u; i++){
                            resources = retval[i].resources;
                            for(j = 0, v = resources.length; j<v;j++){
                                resource = resources[j];
                                if (!by_resource[resource]){
                                    by_resource[resource] = [retval[i]];
                                } else {
                                    by_resource[resource].push(retval[i]);
                                }
                            }
                        }

                        //organize each set of elements by start date
                        var resource_keys = Object.keys(by_resource);
                        for ( i=0,u=resource_keys.length; i<u;i++ ) {
                            by_resource[resource_keys[i]] = sort_by_field_name(by_resource[resource_keys[i]], 'startDate');
                        }

                        //check for overlap. If there's overlap check next id. If next id does not exist create it and add the item.
                        var is_overlap = true;
                        while ( is_overlap ) {
                            is_overlap = context.fix_calendar_overlap(by_resource);
                        }
                        d.resolve(retval);

                        context.debugLog('load_calendar_dataSource load success_func', false);
                    };

                    if ( !load_options.skip ) { //null or 0 --happens when filter is changed.
                        if ( load_options.skip === 0 ) {
                            send_data.last_id = undefined;
                            send_data.skip = undefined;
                        }

                        var conditions, scheduler = load_options.dxScheduler;
                        if ( scheduler && scheduler.endDate && scheduler.startDate ) {
                            var f1, f2;
                            if ( fields.length > 1 ){
                                conditions = {"$or":[]};
                                for (var i = 0, u = fields.length; i < u; i++){
                                    f1 = fields[i].start_datetime_field||fields[i].end_datetime_field;
                                    f2 = fields[i].end_datetime_field||fields[i].start_datetime_field;
                                    conditions["$or"][i] = {"$and":[{"$or":[{},{}]},{"$or":[{},{}]}]};
                                    conditions["$or"][i]["$and"][0]["$or"][0][f1] = {"$lte":scheduler.endDate};
                                    conditions["$or"][i]["$and"][0]["$or"][1][f1] = {"$eq":null};
                                    conditions["$or"][i]["$and"][1]["$or"][0][f2] = {"$gte":scheduler.startDate};
                                    conditions["$or"][i]["$and"][1]["$or"][1][f2] = {"$eq":null};
                                }
                            }
                            else{
                                f1 = fields[0].start_datetime_field||fields[0].end_datetime_field;
                                f2 = fields[0].end_datetime_field||fields[0].start_datetime_field;
                                conditions = {"$and":[{"$or":[{},{}]},{"$or":[{},{}]}]};
                                conditions["$and"][0]["$or"][0][f1] = {"$lte":scheduler.endDate};
                                conditions["$and"][0]["$or"][1][f1] = {"$eq":null};
                                conditions["$and"][1]["$or"][0][f2] = {"$gte":scheduler.startDate};
                                conditions["$and"][1]["$or"][1][f2] = {"$eq":null};
                            }
                        }

                        if ( context.props.export_grid_conditions && Object.keys(context.props.export_grid_conditions).length ) {
                            if ( conditions ){
                                send_data.conditions = {"$and":[
                                    context.props.export_grid_conditions,
                                    conditions
                                ]}
                            }
                            else{
                                send_data.conditions = context.props.export_grid_conditions;
                            }
                        }
                        else{
                            send_data.conditions = conditions;
                        }

                        if ( load_options.sort && Array.isArray(load_options.sort) ) {
                            send_data.sort = make_sorting_conditions_from_devextreme_sort(load_options.sort);
                        }
                        else {
                            send_data.sort = undefined;
                        }
                    }
                    else {
                        if ( load_options.sort && Array.isArray(load_options.sort) ) {
                            send_data.skip = load_options.skip;
                            send_data.last_id = undefined; //not used in sorted paging
                        }
                    }

                    send_data.app_object_code = context.props.app_object_code;
                    send_data.sort = context.props.apply_app_object_sorting(send_data.sort);
                    var char_count = 0;
                    if ( send_data.sort ) {
                        char_count = ( JSON.stringify(send_data.sort).match(/:/g) || []).length;
                    }
                    if ( char_count > 1 ) {
                        send_data.sort = JSON.stringify(send_data.sort).replace(/[:]/,'.');
                        send_data.sort = jQuery.parseJSON(send_data.sort);
                    }
                    if ( send_data.sort ) {
                        if ( !send_data.skip ) {
                            send_data.skip = 0;
                        }
                        send_data.last_id = undefined; //not used in sorted paging
                    }

                    var complete_func = function(e){
                        context.debugLog('load_calendar_dataSource load complete_func', true);

                        context.props.handle_executing(false);
                        context.props.set_data_list_state({ loading: false });
                        context.props.set_data_list_state({ last_load: "calendar_view" });

                        context.debugLog('load_calendar_dataSource load complete_func', false);
                    };

                    invoke_method(context.props.entity_attributes.entity, "get_calendar_data", send_data, success_func, error_func, complete_func);

                    context.debugLog('load_calendar_dataSource load', false);
                    return d.promise();
                },
                remove: function (key) {
                    context.debugLog('load_calendar_dataSource remove', true);
                    context.debugLog('load_calendar_dataSource remove', false);
                },
                insert: function (values) {
                    context.debugLog('load_calendar_dataSource insert', true);
                    context.debugLog('load_calendar_dataSource insert', false);
                },
                update: function (key, values) {
                    context.debugLog('load_calendar_dataSource update', true);
                    context.debugLog('load_calendar_dataSource update', false);
                }
            });
        this.debugLog('load_calendar_dataSource', false);
        return data_source;
    },

    fix_calendar_overlap: function(by_resource){
        var item,
            items,
            resource,
            next_resource,
            next_resource_name,
            base_resource_name,
            resource_keys = Object.keys(by_resource),
            same_resource_count,
            is_overlap = false;

        for ( var i=0;i<resource_keys.length;i++ ) {
            resource = resource_keys[i];
            same_resource_count = 0;
            base_resource_name = resource;

            resource_keys.forEach(function(currentValue){
                if ( resource.indexOf(currentValue) == 0 ) {
                    if (!base_resource_name || base_resource_name.length > currentValue.length){
                        base_resource_name = currentValue;
                    }
                }
            });

            resource_keys.forEach(function(currentValue){
                if ( currentValue.indexOf(base_resource_name) == 0 ) {
                    same_resource_count++;
                }
            });

            next_resource_name = base_resource_name + same_resource_count;
            next_resource = by_resource[next_resource_name];
            items = by_resource[resource];

            for ( var j=0; j<items.length-1;j++ ) {
                if (items[j].endDate>items[j+1].startDate){
                    is_overlap = true;
                    item = items[j+1];

                    //update the item.
                    if ( Array.isArray(item.resources) ) {
                        if ( item.resources[item.resources.indexOf(resource)] == -1 ) {
                            item.resources.push(next_resource_name);
                        }
                        else {
                            item.resources[item.resources.indexOf(resource)] = next_resource_name;
                        }
                    }
                    else{
                        item.resources = [next_resource_name];
                    }

                    //update the resource array.
                    if ( !next_resource ) {
                        by_resource[next_resource_name] = [item];
                        resource_keys.push(next_resource_name);
                        next_resource = [item];
                    }
                    else{
                        by_resource[next_resource_name].push(item);
                    }

                    items.splice(j+1,1);
                }
            }
        }
        return is_overlap;
    },

    rebuild_resources: function(){
        this.setState({ resources: this.build_resources() });
    },
    build_resources: function (){
        var context = this,
            retval = [],
            workflow_states = context.props.app_object.workflow_states,
            workflow_states_ids = workflow_states ? Object.keys(workflow_states) : [],
            current_state;

        for ( var i = 0, l = workflow_states_ids.length; i < l; i++ ) {
            current_state = workflow_states[workflow_states_ids[i]];
            if ( current_state ) {
                retval.push({
                    id: workflow_states_ids[i],
                    text: current_state.caption,
                    color: current_state.view_options.class_color
                });
            }
        }
        retval.push({
            color: 'teal',
            id: 'none',
            text: 'None'
        });

        return [{
            dataSource: retval,
            label: 'Status',
            field: 'resources'
        }];
    },

    /**
     * Click on an element of the calendar
     * @param data
     */
    on_calendar_item_click: function(data){
        var context = this;
        context.debugLog('on_calendar_item_click', true);

        data.cancel = true;
        if ( data.appointmentData ) {
            data = data.appointmentData;
        }

        var data_row = data.record;
        if ( data_row && data_row._id ) {
            var app_object = {};
            app_object.code = context.props.app_object.edit_app_object;
            app_object.data_row = data_row;
            app_object._id = data_row._id;

            context.props.on_click_data_list_element(app_object, data_row._id);
        }

        context.debugLog('on_calendar_item_click', false);
    },

    /**
     * Returns the calendar view instance
     * @returns {*}
     */
    getCalendarViewInstance: function () {
        return this.refs.refCalendarView.getCalendarViewInstance();
    },

    render: function() {
        var context = this;
        context.debugLog("render", true);

        var calendar_view_component = (
            <div key={"tree_view_key"}>
                <EditorDevExtremeCalendarView
                    ref="refCalendarView"
                    dataSource={context.calendar_data_source}
                    resources={context.state.resources}
                    attributes={context.props.entity_attributes}
                    onAppointmentClick={context.on_calendar_item_click}
                    getHeight={context.props.update_height}
                    grid_id={context.props.grid_id}
                    initDataSource={context.init_calendar_datasource}
                    readOnly={""}
                    buildResources={context.rebuild_resources} />
            </div>
        );

        context.debugLog("render", false);
        return (
            <div className="widget-body"
                 style={{display:context.props.showBodyDatagrid, overflow:'hidden'}}
                 data-unique-id={context.props.grid_id}>
                {context.props.description_grid}
                {context.props.error_component}
                {calendar_view_component}
                {context.props.export_component}
            </div>
        );
    }
});
