/**
 * ============================================================
 * Description:     Tree view component
 * Creation Date:   11/29/16
 * Author:          Anthony C
 * ============================================================
 **/

var TreeViewComponent = React.createClass({
    debugging: false,
    tab: 0,
    debugLog: function (msg, start){
        if (this.debugging){
            if (!start) {
                this.tab--;
            }
            try{
                console.log((start?'-> ':'<- ') + Array(this.tab+1).join('\t') + msg + this.tab);
            } catch(e){console.log((start?'-> ':'<- ') + msg + this.tab);}
            if (start) {
                this.tab++;
            }
        }
    },
    propTypes: {
        app_object: React.PropTypes.object,
        call_data_list_set_state: React.PropTypes.func,
        description_grid: React.PropTypes.object,
        entity_attributes: React.PropTypes.object,
        error_component: React.PropTypes.object,
        export_grid_conditions: React.PropTypes.object,
        grid_id: React.PropTypes.string,
        handle_executing: React.PropTypes.func,
        on_click_add_tree_view_element: React.PropTypes.func,
        on_click_data_list_element: React.PropTypes.func,
        set_data_list_state: React.PropTypes.func,
        showBodyDatagrid: React.PropTypes.string
    },
    getInitialState: function() {
        return {};
    },
    componentDidMount: function() {
        var context = this;
        context.debugLog('componentDidMount', true);

        // Initialization of the component, loading the data
        context.get_tree_view_data();

        context.debugLog('componentDidMount', false);
    },

    /**
     * Fetching and organizing all the data to display the tree the way we want.
     */
    get_tree_view_data: function() {
        var context = this;
        context.debugLog('get_tree_view_data', true);

        if ( !context.treeview_searching ) {
            var send_data = {
                conditions: {},
                last_id: undefined
            };

            if ( context.props.export_grid_conditions ) {
                send_data.conditions = context.props.export_grid_conditions;
            }

            var hierarchical_tree = function(item_arr, identifier_field, parent_field, children_field, transformation_function) {
                if(!item_arr || !item_arr.length || !Array.isArray(item_arr)) {
                    return {};
                }
                var dic_func = function(entry) {
                    entry = (transformation_function) ? transformation_function(entry) : entry;
                    var ret_entry = {key: entry[identifier_field]};
                    ret_entry.value = entry;
                    return ret_entry;
                };
                var dictionary_of_entities_by_identifier = convert_to_dictionary(item_arr, dic_func);
                dictionary_tree(dictionary_of_entities_by_identifier, parent_field, children_field);
                return to_array(dictionary_of_entities_by_identifier);
            };

            var dictionary_tree = function(dictionary_of_all_entities, parent_field, children_field) {
                var delete_children_arr = [];
                for ( var entity_id in dictionary_of_all_entities ) {
                    if ( dictionary_of_all_entities.hasOwnProperty(entity_id) && dictionary_of_all_entities[entity_id] ) {
                        var entity = dictionary_of_all_entities[entity_id];
                        var parent_entity_id = entity[parent_field];
                        if ( parent_entity_id != null ) {
                            if ( dictionary_of_all_entities[parent_entity_id] ) {
                                dictionary_of_all_entities[parent_entity_id][children_field] = dictionary_of_all_entities[parent_entity_id][children_field] || [];
                                dictionary_of_all_entities[parent_entity_id][children_field].push(entity);
                                delete_children_arr.push(entity_id);
                            }
                        }
                    }
                }

                if ( delete_children_arr.length ) {
                    for ( var i = 0; i < delete_children_arr.length; i++ ) {
                        delete dictionary_of_all_entities[delete_children_arr[i]];
                    }
                }

                return false;
            };

            var success_func = function (data) {
                context.debugLog('get_treeview_data success_func', true);

                //SORT_TREE
                data.entity_instances.sort(function(a, b){
                    a = make_friendly_caption(context.props.entity_attributes.caption_fields, a, context.props.entity_attributes.attributes);
                    b = make_friendly_caption(context.props.entity_attributes.caption_fields, b, context.props.entity_attributes.attributes);
                    if(a && b){
                        return ((a.toUpperCase() > b.toUpperCase())) - ((a.toUpperCase() < b.toUpperCase()));
                    }
                    else{
                        return !a?!b?0:-1:1;
                    }
                });

                var datasource = hierarchical_tree(data.entity_instances, "id", "hierarchical_parent", "items", function (a) {
                    return {
                        id: a._id,
                        text: make_friendly_caption(context.props.entity_attributes.caption_fields, a, context.props.entity_attributes.attributes),
                        hierarchical_parent: (a.hierarchical_parent) ? a.hierarchical_parent.entity_id : null,
                        expanded: true
                    };
                });

                context.setState({ treeview_datasource: datasource });
                context.treeview_searching = false;

                context.debugLog('get_treeview_data success_func', false);
            };
            var error_func = function (error) {
                context.debugLog('get_treeview_data error_func', true);

                if ( error.responseJSON ) {
                    context.props.call_data_list_set_state({ error: error.responseJSON.message });
                } else if ( error.message ) {
                    context.props.call_data_list_set_state({ error: error.message });
                } else if ( error ) {
                    context.props.call_data_list_set_state({ error: error });
                }

                context.treeview_searching = false;

                context.debugLog('get_treeview_data error_func', false);
            };
            var complete_func = function (e) {
                context.debugLog('get_treeview_data complete_func', true);

                context.props.handle_executing(false);
                context.props.set_data_list_state({ loading: false });
                context.props.set_data_list_state({ last_load: "tree_view" });

                context.debugLog('get_treeview_data complete_func', false);
            };

            context.treeview_searching = true;

            invoke_method(context.props.entity_attributes.entity, "find_all", send_data, success_func, error_func, complete_func);
        }

        context.debugLog('get_tree_view_data', false);
    },

    /**
     * Click on a node of the tree
     * @param e
     */
    tree_view_click: function(e){
        var context = this;
        context.debugLog("tree_view_click", true);

        if ( e.itemData.id ) {
            var app_object = {};
            app_object.code = context.props.app_object.edit_app_object;
            app_object.data_row = e.itemIndex;
            app_object._id = e.itemData.id;

            context.props.on_click_data_list_element(app_object, e.itemData.id);
        }

        context.debugLog("tree_view_click", false);
    },

    /**
     * Building the button to add an element to the tree
     * @returns {XML}
     */
    get_add_button: function() {
        var context = this;
        context.debugLog("get_add_button", true);

        context.debugLog("get_add_button", false);
        return (
            <button
                style={{ "marginTop": "1px" }}
                type="button"
                id={"tree_view_add"}
                onClick={context.props.on_click_add_tree_view_element}
                className=" add-button dx-button"
                tabIndex={-1}>
                <div className='dx-button-content'>
                    <i style={{paddingLeft: "2px"}}
                       className='dx-icon dx-icon-add'>&nbsp;</i>
                </div>
            </button>
        );
    },

    /**
     * Creating the tree view Component
     * @returns {XML}
     */
    getTreeViewComponent: function() {
        var context = this;
        context.debugLog("getTreeViewComponent", true);

        context.debugLog("getTreeViewComponent", false);
        return (
            <div key={"tree_view_key"}>
                <EditorDevExtremeTreeView
                    ref="refTreeView"
                    dataSource={context.state.treeview_datasource}
                    value={context.state.treeview_datasource}
                    onItemClick={context.tree_view_click}
                    readOnly={""} />
            </div>
        );
    },

    /**
     * Returns the tree view instance
     * @returns {*}
     */
    getTreeViewInstance: function() {
        return this.refs.refTreeView.getTreeViewInstance();
    },

    render: function() {
        var context = this;
        context.debugLog("render", true);

        var addButton = this.get_add_button();
        var tree_view_component = this.getTreeViewComponent();

        context.debugLog("render", false);
        return (
            <div className="widget-body"
                 style={{ display: context.props.showBodyDatagrid, overflow: 'hidden' }}
                 data-unique-id={context.props.grid_id}>
                {context.props.description_grid}
                {context.props.error_component}
                <div style={{textAlign :"right"}}>
                    {addButton}
                </div>
                {tree_view_component}
            </div>
        );
    }
});
