/**
 * ========================================================
 * Description: A web control for inputting a "recurrence" type,
 *              which is used to create schedules for tasks.
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/
var EditorRecurrenceInput = React.createClass({
    statics: {
        setTranslation: function () {
            $.tools.recurrenceinput.localize('en',{
                displayUnactivate: R.recurrence_editor_display_unactivate,
                displayActivate: R.recurrence_editor_display_activate,
                add_rules: R.recurrence_editor_add_rules,
                edit_rules: R.recurrence_editor_edit_rules,
                delete_rules: R.recurrence_editor_delete_rules,
                add: R.recurrence_editor_add,
                refresh: R.recurrence_editor_refresh,

                title: R.recurrence_editor_title,
                preview: R.recurrence_editor_preview,
                addDate: R.recurrence_editor_addDate,

                recurrenceType: R.recurrence_editor_recurrenceType,

                dailyInterval1: R.recurrence_editor_dailyInterval1,
                dailyInterval2: R.recurrence_editor_dailyInterval2,

                weeklyInterval1: R.recurrence_editor_weeklyInterval1,
                weeklyInterval2: R.recurrence_editor_weeklyInterval2,
                weeklyWeekdays: R.recurrence_editor_weeklyWeekdays,
                weeklyWeekdaysHuman: R.recurrence_editor_weeklyWeekdaysHuman,

                monthlyInterval1: R.recurrence_editor_monthlyInterval1,
                monthlyInterval2: R.recurrence_editor_monthlyInterval2,
                monthlyDayOfMonth1: R.recurrence_editor_monthlyDayOfMonth1,
                monthlyDayOfMonth1Human: R.recurrence_editor_monthlyDayOfMonth1Human,
                monthlyDayOfMonth2: R.recurrence_editor_monthlyDayOfMonth2,
                monthlyDayOfMonth3: R.recurrence_editor_monthlyDayOfMonth3,
                monthlyWeekdayOfMonth1: R.recurrence_editor_monthlyWeekdayOfMonth1,
                monthlyWeekdayOfMonth1Human: R.recurrence_editor_monthlyWeekdayOfMonth1Human,
                monthlyWeekdayOfMonth2: R.recurrence_editor_monthlyWeekdayOfMonth2,
                monthlyWeekdayOfMonth3: R.recurrence_editor_monthlyWeekdayOfMonth3,
                monthlyRepeatOn: R.recurrence_editor_monthlyRepeatOn,

                yearlyInterval1: R.recurrence_editor_yearlyInterval1,
                yearlyInterval2: R.recurrence_editor_yearlyInterval2,
                yearlyDayOfMonth1: R.recurrence_editor_yearlyDayOfMonth1,
                yearlyDayOfMonth1Human: R.recurrence_editor_yearlyDayOfMonth1Human,
                yearlyDayOfMonth2: R.recurrence_editor_yearlyDayOfMonth2,
                yearlyDayOfMonth3: R.recurrence_editor_yearlyDayOfMonth3,
                yearlyWeekdayOfMonth1: R.recurrence_editor_yearlyWeekdayOfMonth1,
                yearlyWeekdayOfMonth1Human: R.recurrence_editor_yearlyWeekdayOfMonth1Human,
                yearlyWeekdayOfMonth2: R.recurrence_editor_yearlyWeekdayOfMonth2,
                yearlyWeekdayOfMonth3: R.recurrence_editor_yearlyWeekdayOfMonth3,
                yearlyWeekdayOfMonth4: R.recurrence_editor_yearlyWeekdayOfMonth4,
                yearlyRepeatOn: R.recurrence_editor_yearlyRepeatOn,

                range: R.recurrence_editor_range,
                rangeNoEnd: R.recurrence_editor_rangeNoEnd,
                rangeByOccurrences1: R.recurrence_editor_rangeByOccurrences1,
                rangeByOccurrences1Human: R.recurrence_editor_rangeByOccurrences1Human,
                rangeByOccurrences2: R.recurrence_editor_rangeByOccurrences2,
                rangeByEndDate: R.recurrence_editor_rangeByEndDate,
                rangeByEndDateHuman: R.recurrence_editor_rangeByEndDateHuman,

                including: R.recurrence_editor_including,
                except: R.recurrence_editor_except,

                cancel: R.recurrence_editor_cancel,
                save: R.recurrence_editor_save,

                recurrenceStart: R.recurrence_editor_recurrenceStart,
                additionalDate: R.recurrence_editor_additionalDate,
                include: R.recurrence_editor_include,
                exclude: R.recurrence_editor_exclude,
                remove: R.recurrence_editor_remove,

                orderIndexes: [R.recurrence_editor_orderIndexes_first,
                    R.recurrence_editor_orderIndexes_second,
                    R.recurrence_editor_orderIndexes_third,
                    R.recurrence_editor_orderIndexes_fourth,
                    R.recurrence_editor_orderIndexes_last],
                months: [
                    R.recurrence_editor_months_January,
                    R.recurrence_editor_months_February,
                    R.recurrence_editor_months_March,
                    R.recurrence_editor_months_April,
                    R.recurrence_editor_months_May,
                    R.recurrence_editor_months_June,
                    R.recurrence_editor_months_July,
                    R.recurrence_editor_months_August,
                    R.recurrence_editor_months_September,
                    R.recurrence_editor_months_October,
                    R.recurrence_editor_months_November,
                    R.recurrence_editor_months_December
                ],
                shortMonths: [
                    R.recurrence_editor_shortMonths_Jan,
                    R.recurrence_editor_shortMonths_Feb,
                    R.recurrence_editor_shortMonths_Mar,
                    R.recurrence_editor_shortMonths_Apr,
                    R.recurrence_editor_shortMonths_May,
                    R.recurrence_editor_shortMonths_Jun,
                    R.recurrence_editor_shortMonths_Jul,
                    R.recurrence_editor_shortMonths_Aug,
                    R.recurrence_editor_shortMonths_Sep,
                    R.recurrence_editor_shortMonths_Oct,
                    R.recurrence_editor_shortMonths_Nov,
                    R.recurrence_editor_shortMonths_Dec
                ],
                weekdays: [
                    R.recurrence_editor_weekdays_Sunday,
                    R.recurrence_editor_weekdays_Monday,
                    R.recurrence_editor_weekdays_Tuesday,
                    R.recurrence_editor_weekdays_Wednesday,
                    R.recurrence_editor_weekdays_Thursday,
                    R.recurrence_editor_weekdays_Friday,
                    R.recurrence_editor_weekdays_Saturday
                ],
                shortWeekdays: [
                    R.recurrence_editor_shortWeekdays_Sun,
                    R.recurrence_editor_shortWeekdays_Mon,
                    R.recurrence_editor_shortWeekdays_Tue,
                    R.recurrence_editor_shortWeekdays_Wed,
                    R.recurrence_editor_shortWeekdays_Thu,
                    R.recurrence_editor_shortWeekdays_Fri,
                    R.recurrence_editor_shortWeekdays_Sat
                ],

                longDateFormat: R.recurrence_editor_longDateFormat,
                shortDateFormat: R.recurrence_editor_shortDateFormat,

                unsupportedFeatures: R.recurrence_editor_unsupportedFeatures,
                noTemplateMatch: R.recurrence_editor_noTemplateMatch,
                multipleDayOfMonth: R.recurrence_editor_multipleDayOfMonth,
                bysetpos: R.recurrence_editor_bysetpos,
                noRule: R.recurrence_editor_noRule,
                noRepeatEvery: R.recurrence_editor_noRepeatEvery,
                noEndDate: R.recurrence_editor_noEndDate,
                noRepeatOn: R.recurrence_editor_noRepeatOn,
                pastEndDate: R.recurrence_editor_pastEndDate,
                noEndAfterNOccurrences: R.recurrence_editor_noEndAfterNOccurrences,
                alreadyAdded: R.recurrence_editor_alreadyAdded,

                rtemplate: {
                    daily: R.recurrence_editor_rtemplate_daily,
                    mondayfriday: R.recurrence_editor_rtemplate_mondayfriday,
                    weekdays: R.recurrence_editor_rtemplate_weekdays,
                    weekly: R.recurrence_editor_rtemplate_weekly,
                    monthly: R.recurrence_editor_rtemplate_monthly,
                    yearly: R.recurrence_editor_rtemplate_yearly
                }
            });

        }
    },
    getInitialState: function () {
        return {};
    },
    componentWillReceiveProps: function(nextProps) {
        if ( nextProps.value != this.state.value ) {
            this.state.value = nextProps.value;
            $(this.domElement).val(this.state.value);
            this.state.element.loadData(this.state.value);
        }
    },
    componentDidMount: function() {
        var context = this;
        this.state.value = this.props.value;
        $(this.domElement).val(this.state.value);

        this.state.element = $(this.domElement).recurrenceinput(
            {
                readOnly: context.props.readOnly
            }
        );

        $(this.domElement).on('change',function(e){
            context.state.value = $(e.target).val();
            context.props.onChange(context.state.value);
            context.props.onBlur();
        });

        $("#recurrencemodal").on('shown.bs.modal', function () {
            $(this).find("select:first").focus().blur();
        });
    },
    componentWillUnmount: function () {
        $(this.state.element.form).remove();
    },

    render: function() {
        var context = this;
        return <textarea ref={function(ref){context.domElement = ref}} />;
    }
});
