/**
 * ========================================================
 * Description: A web control for picking a Date with a Time
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/
var EditorDevExtremeDateTimePicker = React.createClass({
    width_buffer: 3,
    getInitialState: function () {
        this.state = this.state || {};
        this.set_internal_values(this.props.value);
        return this.state;
    },
    componentWillReceiveProps: function (nextProps) {
        if(nextProps.value !== undefined) {
            if (this.state.value != nextProps.value) { //ref comparison
                if(nextProps.value && this.state.last_change_event_values && (!this.state.last_change_event_values.time_value || !this.state.last_change_event_values.date_value)) {
                    var comparison_date = this.combine_times(this.state.date_value, this.state.time_value);
                    if(comparison_date && (comparison_date.getTime() == nextProps.value.getTime())) {
                        //don't set internal values
                        return;
                    }
                }
                this.set_internal_values(nextProps.value);
            }
        }
    },
    componentDidMount: function () {
        //this.set_internal_values(this.props.value);
    },
    set_internal_values: function(new_value) {
        this.state.value = new_value;
        this.state.time_value = ((this.state.value) ? new Date(this.state.value) : this.state.value);
        this.state.date_value = ((this.state.value) ? new Date(this.state.value) : this.state.value);
    },
    set_time_properties_from_value: function(date, time_value) {
        date.setHours((time_value && time_value.getHours) ? time_value.getHours() : 0);
        date.setMinutes((time_value && time_value.getMinutes) ? time_value.getMinutes() : 0);
        date.setSeconds(0);
        date.setMilliseconds(0);
    },
    combine_times: function(date_value, time_value) {
        var combined_date = ((date_value) ? new Date(date_value) : new Date());
        this.set_time_properties_from_value(combined_date, time_value);
        return combined_date;
    },
    on_time_change: function(new_value) {
        this.state.time_value = new_value;
        this.call_change_event();
    },
    on_date_change: function(new_value) {
        this.state.date_value = new_value;
        this.call_change_event();
    },
    call_change_event: function() {
        this.state.last_change_event_values = null;
        if(this.state.date_value || this.state.time_value) {
            this.state.last_change_event_values = {date_value: this.state.date_value, time_value: this.state.time_value};
        }
        return this.props.onChange((this.state.last_change_event_values) ? this.combine_times(this.state.last_change_event_values.date_value, this.state.last_change_event_values.time_value) : null);
    },
    render: function () {
        var input_element_date =
            <EditorDevExtremeDatePicker onChange={this.on_date_change}
                                        key={this.props.unique_control_id + "_date"}
                                        is_mobile={this.props.is_mobile}
                                        onBlur={this.props.onBlur}
                                        value={this.state.date_value}
                                        is_invalid={!!(this.state.time_value && !this.state.date_value)}
                                        readOnly={this.props.readOnly}/>;
        var input_element_time =
            <EditorDevExtremeTimePicker onChange={this.on_time_change}
                                        key={this.props.unique_control_id + "_time"}
                                        value={this.state.time_value}
                                        is_mobile={this.props.is_mobile}
                                        is_invalid={!!(this.state.date_value && !this.state.time_value)}
                                        onBlur={this.props.onBlur}
                                        readOnly={this.props.readOnly}/>;
        return <div key={this.props.unique_control_id + "_datetime"}>
            <div style={{display:"inline-block", marginRight:"3px"}} >{input_element_date}</div>
            <div style={{display:"inline-block"}} >{input_element_time}</div>
        </div>;
    }
});
