"use strict";
/**
 ==================================================================================
 Description:       desc
 Creation Date:     7/13/2016
 Author:            SebasWebcreek
 ==================================================================================
 */
var temp = 'AIzaSyBySfAAwes8wzncm5Ym1wBwBO-uv4pqnT8';

var EditorDevExtremeMapView = React.createClass({
    debugging: false,
    tab: 0,
    debugLog: function (msg, start){
        if (this.debugging){
            if (!start) {
                this.tab--;
            }
            try{
                console.log((start?'-> ':'<- ') + Array(this.tab+1).join('\t') + msg + this.tab);
            } catch(e){console.log((start?'-> ':'<- ') + msg + this.tab);}
            if (start) {
                this.tab++;
            }
        }
    },
    propTypes: {
        onItemClick: React.PropTypes.func,
        markers: React.PropTypes.array,
        grid_id: React.PropTypes.string,
        getHeight: React.PropTypes.func,
        readOnly: React.PropTypes.bool,
        get_mapview_data: React.PropTypes.func,
        map_fields: React.PropTypes.array
    },

    /*************/
    /* LIFECYCLE */
    /*************/
    getInitialState: function () {
        var context = this;
        context.debugLog("getInitialState", true);

        var get_keys = function() {
            var promise = new Promise(function(resolve, reject){
                var request ={};
                request.code="google_drive_public_keys";
                do_authenticated_http_call({
                    method: "POST",
                    url: "/api/settings/get_system_setting",
                    contentType: "application/json",
                    dataType: 'json',
                    data: JSON.stringify(request),
                    success: resolve,
                    error: reject
                });
            });
            return promise;
        };

        var store_keys = function (data) {
            localStorage.setItem("developerKey", data.data.google_drive_developer_key);
            localStorage.setItem("ClientId", data.data.google_drive_browser_key);
            if (context.isMounted){
                context.init();
            }
        };

        get_keys()
            .then(store_keys);

        context.debugLog("getInitialState", false);
        return {};
    },
    componentDidMount: function(){
        var context = this;
        context.debugLog("componentDidMount", true);

        this.isMounted = true;
        this.init();

        context.debugLog("componentDidMount", false);
    },
    componentWillReceiveProps: function (nextProps) {
        var context = this;
        context.debugLog("componentWillReceiveProps", true);

        if ( this.state.element ) {
            try {
                var element = this.state.element.dxMap('instance');
                if ( nextProps.markers != this.state.markers ) {
                    this.state.markers = nextProps.markers;
                    for ( var i = 0, u = this.state.markers.length; i<u; i++ ) {
                        this.state.markers[i].onClick = this.bind_tooltips;
                    }
                    element.option("markers", this.state.markers);
                }
            } catch(e){}
        }

        context.debugLog("componentWillReceiveProps", false);
    },
    componentWillUnmount: function(){
        var context = this;
        context.debugLog("componentWillUnmount", true);

        $('.map-tooltip').off('click');
        window.removeEventListener("resize", this.updateDimensions);

        context.debugLog("componentWillUnmount", false);
    },

    /***********/
    /* METHODS */
    /***********/
    /**
     * Initialize the map
     */
    init: function () {
        var context = this;
        context.debugLog("init", true);

        if ( !context.isInit && localStorage.getItem("developerKey") ) {
            context.isInit = true;
            var mapMarkers = [];
            var markerprops = context.props.markers;
            if ( !markerprops ) {
                markerprops = mapMarkers;
            }
            var hasMarkers = markerprops.length;
            context.state.markers = context.props.markers;
            context.state.dataSource = context.props.dataSource;
            // init last bounds
            context.setLastBoundsEvent({
                value: {
                    northEast: {
                        lat: 90,
                        lng: 180
                    },
                    southWest: {
                        lat: -90,
                        lng: -180
                    }
                }
            });
            // defines if a movement has been done recently, in which case the next movements will be disabled for a time
            // (defined by BUSY_THROTTLE_DELAY) and only the last movement will be saved and executed once the delay will be over.
            context.setBusyThrottle(false);
            context.setBusyThrottleDelay(1000);

            // map config
            var config = {
                readOnly:   context.props.readOnly,
                dataSource: context.props.dataSource,
                autoAdjust: true,
                markers:    markerprops,
                controls:   true,
                zoom:       hasMarkers?20:4,
                width:      '100%',
                height:     context.props.getHeight,
                key:        {google: localStorage.getItem("developerKey")},
                onOptionChanged: context.onMapMove
            };
            if (!hasMarkers){
                config.center = [36.2460287,-98.7244602]; //United States
            }
            // initalizing dxMap
            context.state.element = $(context.domElement).dxMap(config);

            // add event on resize
            window.addEventListener("resize", context.updateDimensions);
        }

        context.debugLog("init", false);
    },

    /**
     * Called when a move has been made on the map
     * @param e
     */
    onMapMove: function(e) {
        var context = this;

        var coords = e.value;
        if (e.name == "bounds" && coords && coords.northEast && coords.southWest) {
            // a movement has been done recently
            if (context.isBusyThrottle()) {
                // we save the last movement on the map
                context.savePendingMapMove(e);
                return;
            }

            // means we're actually updating markers
            context.setBusyThrottle(true);

            // function to be executed after some time not to refresh too often the view
            var readyForNextMapMove = function() {
                // we apply here the last movement registered
                context.applyPendingMapMove();
                // allowing next movement to be directly intercepted
                context.setBusyThrottle(false);
            };
            // all the movements on the map will be ignored for the time defined by BUSY_THROTTLE_DELAY
            setTimeout(readyForNextMapMove, context.getBusyThrottleDelay());

            // save last bounds event
            context.setLastBoundsEvent(e);

            // updating the markers on the map
            context.updateMarkers(e);
        }
    },

    /**
     * Checking if the coords of the map changed after the last movement
     * @param last_bounds
     * @param e
     * @returns {boolean}
     */
    hasSameCoords: function(coords) {
        var context = this,
            last_bounds_event = context.getLastBoundsEvent(),
            last_coords = last_bounds_event.value;

        return last_coords.northEast.lng == coords.northEast.lng
            && last_coords.northEast.lat == coords.northEast.lat
            && last_coords.southWest.lng == coords.southWest.lng
            && last_coords.southWest.lat == coords.southWest.lat;
    },

    /**
     * Update markers on the map
     * @param e
     */
    updateMarkers: function(e) {
        var context = this,
            coords_conditions = context.getCoordsConditions(e.value);

        context.props.get_mapview_data(coords_conditions, function(new_markers) {
            e.component.option("autoAdjust", false);
            // replace markers in the state
            context.state.markers = new_markers;
            for (var i = 0, u = context.state.markers.length; i<u;i++){
                context.state.markers[i].onClick = context.bind_tooltips;
            }
            e.component.option("markers", new_markers);
        });
    },

    /**
     * Bind tooltips on the markers (onClick event)
     */
    bind_tooltips: function(){
        var context = this;
        context.debugLog("bind_tooltips", true);

        var markers = $('.map-tooltip'), context = this;
        if (markers && (context.props.markers || context.state.markers) && markers.length) {
            markers.off('click');
            markers.on('click', context.props.onItemClick);
            if ( !this.markers_count || this.markers_count != markers.length ) {
                this.markers_count = markers.length;
                setTimeout(function(){context.bind_tooltips();}, 100);
            } else {
                this.markers_count = 0;
            }
        }
        else {
            setTimeout(function(){context.bind_tooltips();}, 100);
        }

        context.debugLog("bind_tooltips", false);
    },

    /**
     * Returns the coord conditions
     * @param e
     * @returns {{$or}|*}
     */
    getCoordsConditions: function(coords) {
        var context = this,
            coordinate_bounds = {
                lng_min: (coords.southWest.lng < coords.northEast.lng) ? coords.southWest.lng : -180,
                lng_max: (coords.southWest.lng < coords.northEast.lng) ? coords.northEast.lng : 180,
                lat_min: (coords.southWest.lat < coords.northEast.lat) ? coords.southWest.lat : -90,
                lat_max: (coords.southWest.lat < coords.northEast.lat) ? coords.northEast.lat : 90,
            };

        return make_conditions_using_map_fields(context.props.map_fields, coordinate_bounds);
    },

    /**
     * Register the movement on the map
     * @param e
     */
    savePendingMapMove: function(e) {
        var context = this;
        context.pendingMapMoveEvent = e;
    },
    /**
     * Apply the last movement registered on the map
     */
    applyPendingMapMove: function() {
        var context = this;

        if (context.pendingMapMoveEvent) {
            context.updateMarkers(context.pendingMapMoveEvent);
            context.pendingMapMoveEvent = null;
        } else {
            context.updateMarkers(context.getLastBoundsEvent());
        }
    },

    /**
     * Updating the component on resize
     */
    updateDimensions: function(){
        var context = this;
        context.debugLog("updateDimensions", true);

        this.state.element.dxMap('instance').repaint();

        context.debugLog("updateDimensions", false);
    },

    /**
     * Returns the map view instance
     * @returns {*}
     */
    getMapViewInstance: function () {
        return this.state.element.dxMap('instance');
    },

    /*********************/
    /* GETTERS / SETTERS */
    /*********************/
    setBusyThrottle: function(busyThrottle) {
        this.busy_throttle = busyThrottle;
    },
    isBusyThrottle: function() {
        return this.busy_throttle;
    },
    setBusyThrottleDelay: function(busyThrottleDelay) {
        this.BUSY_THROTTLE_DELAY = busyThrottleDelay;
    },
    getBusyThrottleDelay: function() {
        return this.BUSY_THROTTLE_DELAY;
    },
    setLastBoundsEvent: function(lastBoundsEvent) {
        this.last_bounds_event = lastBoundsEvent;
    },
    getLastBoundsEvent: function() {
        return this.last_bounds_event;
    },

    /**********/
    /* RENDER */
    /**********/
    render: function () {
        var context = this;
        context.debugLog("render", true);

        var className = "";
        if ( this.props.add_button ) {
            className = "add-button-margin";
        }

        context.debugLog("render", false);
        return (
            <input class="form-control"/>,
            <div className={className} ref={function(ref){context.domElement = ref}}/>
        );
    }
});
