/**
 * ============================================================
 * Description:     Map view component
 * Creation Date:   11/29/16
 * Author:          Anthony C
 * ============================================================
 **/

var MapViewComponent = React.createClass({
    debugging: false,
    tab: 0,
    debugLog: function (msg, start){
        if (this.debugging){
            if (!start) {
                this.tab--;
            }
            try{
                console.log((start?'-> ':'<- ') + Array(this.tab+1).join('\t') + msg + this.tab);
            } catch(e){console.log((start?'-> ':'<- ') + msg + this.tab);}
            if (start) {
                this.tab++;
            }
        }
    },
    propTypes: {
        app_object: React.PropTypes.object,
        call_data_list_set_state: React.PropTypes.func,
        description_grid: React.PropTypes.object,
        entity_attributes: React.PropTypes.object,
        entity_instances: React.PropTypes.array,
        error_component: React.PropTypes.object,
        export_grid_conditions: React.PropTypes.object,
        grid_id: React.PropTypes.string,
        handle_executing: React.PropTypes.func,
        on_click_data_list_element: React.PropTypes.func,
        row_count: React.PropTypes.number,
        set_data_list_state: React.PropTypes.func,
        showBodyDatagrid: React.PropTypes.string,
        update_height: React.PropTypes.func
    },
    getInitialState: function() {
        var context = this;
        context.debugLog('getInitialState', true);

        context.debugLog('getInitialState', false);
        return {
            entity_instances: context.props.entity_instances,
            map_markers: undefined
        };
    },
    componentWillMount: function() {
        var context = this;
        context.debugLog('componentWillMount', true);

        // Initialization of the component, loading the data
        context.getMapViewData();

        context.debugLog('componentWillMount', false);
    },
    componentWillReceiveProps: function(nextProps) {
        var context = this;
        context.debugLog('componentWillReceiveProps', true);

        // The component was updated, we re-load the data
        context.getMapViewData(context.state.coordinate_conditions, context.state.special_callback);

        context.debugLog('componentWillReceiveProps', false);
    },

    /**
     * Fetching all the data
     */
    getMapViewData: function(coordinate_conditions, special_callback) {
        var context = this;
        context.debugLog('getMapViewData', true);

        context.state.coordinate_conditions = coordinate_conditions;
        context.state.special_callback = special_callback;

        if ( !context.mapview_searching ) {
            var send_data = {
                last_id: undefined
            };

            if ( context.props.export_grid_conditions && Object.keys(context.props.export_grid_conditions).length ) {
                if ( coordinate_conditions && Object.keys(coordinate_conditions).length ) {
                    send_data.conditions = {$and: [context.props.export_grid_conditions, coordinate_conditions]};
                }
                else {
                    send_data.conditions = context.props.export_grid_conditions;
                }
            }
            else {
                send_data.conditions = coordinate_conditions || {};
            }

            if ( context.props.row_count ) {
                send_data.limit = context.props.row_count;
            }
            else {
                send_data.limit = 50;
            }

            var success_func = function (data) {
                context.debugLog('getMapViewData success_func', true);

                var caption_fields = context.props.entity_attributes.caption_fields,
                    mapMarkers = [],
                    mapFields = context.props.app_object.custom_properties.map_fields;

                for ( var j = 0, v = mapFields.length; j<v; j++ ) {
                    var coordinates_field = mapFields[j].coordinates_field;
                    var marker_props = data.entity_instances;

                    for ( var i =0; i<marker_props.length; i++ ) {
                        var new_marker = {};
                        var tooltip_text = "";

                        for ( var l =0; l<caption_fields.length; l++ ) {
                            var caption_name = caption_fields[l];
                            var caption_values = marker_props[i][caption_name];

                            if ( typeof caption_values == "object" ) {
                                caption_values ="";
                            }

                            if ( !tooltip_text && caption_values ) {
                                tooltip_text = caption_values;
                            }
                            else if ( caption_values ) {
                                tooltip_text = tooltip_text +" - " + caption_values;
                            }
                        }

                        new_marker.title = marker_props[i].doc_num;
                        new_marker.tooltip = {text: "<a id='" + marker_props[i]._id + "' class='map-tooltip'>"+tooltip_text+"</a>", isShown: false};

                        var item = get_json_data_using_field_paths(coordinates_field,marker_props[i]);
                        if ( item ) {
                            new_marker.location = [item.latitude, item.longitude];
                            mapMarkers.push(new_marker);
                        }
                    }
                }

                context.mapview_searching = false;

                if ( special_callback ) {
                    context.state.entity_instances = data.entity_instances;
                    context.state.map_markers = mapMarkers;
                    context.debugLog('getMapViewData success_func', false);
                    return special_callback(mapMarkers);
                }
                else {
                    context.setState({ entity_instances: data.entity_instances, map_markers: mapMarkers });
                }

                context.debugLog('getMapViewData success_func', false);
            };
            var error_func = function (error) {
                context.debugLog('getMapViewData error_func', true);

                if (error.responseJSON) {
                    context.props.call_data_list_set_state({ error: error.responseJSON.message });
                } else if (error.message) {
                    context.props.call_data_list_set_state({ error: error.message });
                } else if (error) {
                    context.props.call_data_list_set_state({ error: error });
                }

                context.mapview_searching = false;

                context.debugLog('getMapViewData error_func', false);
            };
            var complete_func = function (e) {
                context.debugLog('getMapViewData complete_func', true);

                context.props.handle_executing(false);
                context.props.set_data_list_state({ loading: false });
                context.props.set_data_list_state({ last_load: "map_view" });

                context.debugLog('getMapViewData complete_func', false);
            };

            context.mapview_searching = true;

            invoke_method(context.props.entity_attributes.entity, "get_map_data", send_data, success_func, error_func, complete_func);
        }

        context.debugLog('getMapViewData', false);
    },
    /**
     * Click on an element of the map
     * @param e
     */
    map_view_click:function(e){
        var context = this,
            data = context.state.entity_instances;
        context.debugLog('map_view_click', true);

        e.itemData = find_by_field('_id', e.target.id, data);
        if ( e.itemData ) {
            var app_object = {};
            app_object.code = context.props.app_object.edit_app_object;
            app_object.data_row = find_index_by_id(data, e.itemData._id);
            app_object._id = e.itemData._id;

            context.props.on_click_data_list_element(app_object, e.itemData._id);
        }

        context.debugLog('map_view_click', false);
    },

    /**
     * Returns the map view instance
     * @returns {*}
     */
    getMapViewInstance: function () {
        return this.refs.refMapView.getMapViewInstance();
    },

    render: function() {
        var context = this;
        context.debugLog("render", true);

        var map_view_component = (
            <div key={"map_view_key"}>
                <EditorDevExtremeMapView
                    ref="refMapView"
                    onItemClick={context.map_view_click}
                    markers={context.state.map_markers}
                    grid_id={context.props.grid_id}
                    getHeight={context.props.update_height}
                    readOnly={""}
                    get_mapview_data={context.getMapViewData}
                    map_fields={context.props.app_object.custom_properties.map_fields} />
            </div>
        );

        context.debugLog("render", false);
        return (
            <div className="widget-body"
                 style={{display:context.props.showBodyDatagrid, overflow:'hidden'}}
                 data-unique-id={context.props.grid_id}>
                {context.props.description_grid}
                {context.props.error_component}
                {map_view_component}
            </div>
        );
    }
});
