/**
 * ========================================================
 * Description: A web control for a masked textbox
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/
var EditorDevExtremeMaskedTextBox = React.createClass({
    getInitialState: function () {
        return {};
    },
    componentWillReceiveProps: function (nextProps) {
        var element = this.state.element.dxTextBox('instance');
        if (nextProps.value != this.state.value) {
            this.setState({value: nextProps.value});
            this.modified = true;
            element.option("value", this.state.value);
        }
    },
    componentDidMount:function()
    {
        var context = this;
        this.state.value = this.props.value; //removing white space
        this.state.element = $(this.domElement).dxTextBox({
            value: String(this.props.value).trim(),
            readOnly:this.props.readOnly,
            mask:this.props.mask,
            maskRules:this.props.maskRules,
            maskChar:this.props.maskChar,
            useMaskedValue:this.props.useMaskedValue,
            rtlEnabled:this.props.rtlEnabled,
            onFocusOut: function(){
                if (this.modified){
                    this.modified = false;
                    context.props.onChange(context.state.value); //removing white space
                    context.props.onBlur();
                }
            },
            onKeyUp:function(data){
                if (context.state.value != data.component._value){
                    this.modified = true;
                    context.state.value = String(data.component._value).trim();
                }
            }
        });
    },
    render: function() {
        var context = this;
        return <div ref={function(ref){context.domElement = ref}} >&nbsp;</div>;
    }
});
