"use strict";
/**
 ==================================================================================
 Description:       Tree View DevExtreme
 Creation Date:     6/24/2016
 Author:            Sebastian Mansfield
 ==================================================================================
 */

var EditorDevExtremeTreeView = React.createClass({
    debugging: false,
    tab: 0,
    debugLog: function (msg, start){
        if (this.debugging){
            if (!start) {
                this.tab--;
            }
            try{
                console.log((start?'-> ':'<- ') + Array(this.tab+1).join('\t') + msg + this.tab);
            } catch(e){console.log((start?'-> ':'<- ') + msg + this.tab);}
            if (start) {
                this.tab++;
            }
        }
    },
    propTypes: {
        dataSource: React.PropTypes.array,
        value: React.PropTypes.array,
        onItemClick: React.PropTypes.func,
        readOnly: React.PropTypes.any
    },
    getInitialState: function () {
        var context = this;
        context.debugLog("getInitialState", true);
        context.debugLog("getInitialState", false);
        return {};
    },
    componentDidMount: function () {
        var context = this;

        this.state.value = this.props.value;
        this.state.dataSource = this.props.dataSource;
        this.state.element = $(this.domElement).dxTreeView({
            readOnly: this.props.readOnly,
            value: this.state.value,
            dataSource: this.props.dataSource,
            onItemClick : this.props.onItemClick,
            showCheckBoxesMode: "none",
            onItemSelectionChanged: function (data) {}
        });
    },
    componentWillReceiveProps: function (nextProps) {
        var context = this;
        context.debugLog("componentWillReceiveProps", true);

        if ( context.state.element ) {
            try {
                this.state.value = nextProps.value;
                var element = context.state.element.dxTreeView('instance');
                // setting the new value
                element.option("value", context.state.value);
                // setting the new data source
                context.state.element.dxTreeView('option', 'dataSource', nextProps.dataSource);
            } catch(e){}
        }

        context.debugLog("componentWillReceiveProps", false);
    },

    /**
     * Returns the tree view instance
     * @returns {*}
     */
    getTreeViewInstance: function() {
        var context = this;
        context.debugLog("getTreeViewInstance", true);

        if ( context.state.element ) {
            context.debugLog("getTreeViewInstance", false);
            return context.state.element.dxTreeView('instance');
        }

        context.debugLog("getTreeViewInstance", false);
        return undefined;
    },

    render: function () {
        var context = this;
        context.debugLog("render", true);

        var className = "";
        if ( this.props.add_button ) {
            className = "add-button-margin";
        }

        context.debugLog("render", false);
        return (
            <input class="form-control"/>,
            <div className={className} ref={function(ref){context.domElement = ref}}/>
        );
    }
});
