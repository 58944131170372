/**
 * ============================================================
 * Description:     Pivot view component
 * Creation Date:   12/06/16
 * Author:          Adam McDaniel
 * ============================================================
 **/

var PivotViewComponent = React.createClass({
    debugging: false,
    tab: 0,
    debugLog: function (msg, start){
        if (this.debugging){
            if (!start) {
                this.tab--;
            }
            try{
                console.log((start?'-> ':'<- ') + Array(this.tab+1).join('\t') + msg + this.tab);
            } catch(e){console.log((start?'-> ':'<- ') + msg + this.tab);}
            if (start) {
                this.tab++;
            }
        }
    },
    propTypes: {
        app_object: React.PropTypes.object,
        app_object_conditions: React.PropTypes.object,
        app_object_handler: React.PropTypes.func,
        call_data_list_set_state: React.PropTypes.func,
        data_list_react_entity: React.PropTypes.object,
        description_grid: React.PropTypes.object,
        entity_attributes: React.PropTypes.object,
        error_component: React.PropTypes.object,
        handle_executing: React.PropTypes.func,
        set_data_list_state: React.PropTypes.func,
        update_height: React.PropTypes.func
    },
    getInitialState: function() {
        this.debugLog('getInitialState', true);
        var context = this;
        context.state = {};
        context.state.grid_id = create_uid();
        pivot_chart_utils.get_initial_pivot_grid_attributes(
            context.props.entity_attributes.entity,
            context.props.entity_attributes.array_of_force_expanded_ref_fields, //Might set-intersect with a user selection in the future
            function (error) {
                context.debugLog('initialize_data_list error', true);
                server_error_action(error);
                if (error.responseJSON) {
                    context.props.call_data_list_set_state({ error: error.responseJSON.message });
                }
                context.debugLog('initialize_data_list error', false);
            },
            function(args) {
                context.initialize_pivot_grid_sync(args); //If this wasn't in a callback then it would need to be in a setTimeout because it needs to let the React component render before the Jquery selectors in this function will work
            }
        );
        this.debugLog('getInitialState', false);
        return context.state;
    },
    componentWillUnmount: function () {
        this.state.pivot_grid_state.componentWillUnmount();
    },
    getPivotViewInstance: function() {
        return $("#" + this.state.grid_id).dxPivotGrid("instance");
    },
    /**
     * Not truly sync, since it passes a callback of function () {context.props.handle_executing(false);}, but no further logic is required to initialize the Pivot Grid
     * @param args
     */
    initialize_pivot_grid_sync: function (args) {
        this.debugLog('initialize_data_list', true);
        var entity_attributes;
        if(args) {
            entity_attributes = args.entity_attributes;
            var context = this;
            var init_grid_columns_properties = {
                for_export: false,
                data_grid_view_component: context,
                data_list_react_entity: context.props.data_list_react_entity,
                app_object: context.props.app_object
            };

            var session_storage_code = context.props.app_object.code + "__pivot_grid";
            var session_pivot_grid_state = sessionStorage[session_storage_code];
            if(session_pivot_grid_state && (session_pivot_grid_state != "") && (session_pivot_grid_state != "{}")) {
                try {
                    session_pivot_grid_state = JSON.parse(session_pivot_grid_state);
                }
                catch(ex) {
                    session_pivot_grid_state = null;
                }
            }
            else {
                session_pivot_grid_state = null;
            }
            session_pivot_grid_state = session_pivot_grid_state || {};

            this.state.pivot_grid_state = pivot_chart_utils.initialize_pivot_grid_sync(
                {
                    chart_section_jquery_selector: $("#" + context.state.grid_id + "_chart"),
                    controls_section_jquery_selector: $("#" + context.state.grid_id + "_dashboard_part_controls"),
                    pivot_grid_section_jquery_selector: $("#" + context.state.grid_id),
                    load_panel_div_selector: $("#" + context.state.grid_id + "_load_panel"),
                    tooltip_div_selector: $("#" + context.state.grid_id + "_chart_tooltip"),
                    mouse_y_offset: 25,
                    mouse_x_offset: 0,
                    app_object_handler: function(local_app_object){
                        if(context.props.data_list_react_entity) {
                            context.props.data_list_react_entity.state.skip_clearing_session = true;
                        }
                        context.props.app_object_handler(local_app_object);
                        if(context.props.data_list_react_entity) {
                            context.props.data_list_react_entity.state.skip_clearing_session = false;
                        }
                    },
                    app_object_conditions: context.props.app_object_conditions,
                    entity_attributes: entity_attributes,
                    update_height_function: function (num, return_as_number) {
                        if(!num) {
                            num = 0;
                        }
                        return context.props.update_height(num + 55, return_as_number);
                    },
                    add_save_option: true,
                    error_function: function (error) {
                        if (error.responseJSON) {
                            context.props.call_data_list_set_state({ error: error.responseJSON.message });
                        } else if (error.message) {
                            context.props.call_data_list_set_state({ error: error.message });
                        } else if (error) {
                            context.props.call_data_list_set_state({ error: error });
                        }
                    },
                    array_of_force_expanded_ref_fields: context.props.entity_attributes.array_of_force_expanded_ref_fields, //Might set-intersect with a user selection in the future
                    session_storage_code: session_storage_code
                },
                init_grid_columns_properties,
                context.props.entity_attributes.name_plural,
                320, //px
                function () {
                    context.props.handle_executing(false);
                    context.props.set_data_list_state({ loading: false });
                    context.props.set_data_list_state({ last_load: "pivot_view" });
                },
                session_pivot_grid_state.initial_data_source || [],
                session_pivot_grid_state.preset_columns_obj,
                true
            );
        }
        this.debugLog('initialize_data_list', false);
    },
    render: function() {
        this.debugLog('render', true);
        this.debugLog('render', false);
        return (<div className="widget-body">
                {this.props.description_grid}
                {this.props.error_component}
                <div className="widget">
                    <div id={this.state.grid_id + "_chart"}>&nbsp;</div>
                    <div id={this.state.grid_id + "_load_panel"}>&nbsp;</div>
                    <div id={this.state.grid_id + "_chart_tooltip"}>&nbsp;</div>
                    <div id={this.state.grid_id + "_dashboard_part_controls"}>&nbsp;</div>
                    <div id={this.state.grid_id}>&nbsp;</div>
                </div>
            </div>
        );
    }
});
