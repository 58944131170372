/**
 * ========================================================
 * Description: Renders screen loading image.
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/
var LoadingComponent = React.createClass({
    render: function () {
        return (<div className="loading-container" style={{backgroundColor:'rgba(93,178,255,0.8)!important'}}>
            <div className="loader-image-container">
                <img style={{"maxWidth": "100%", height:'40px'}} className="loader-image" src="css/icons/LoadingL.gif" alt={R.label_loading} loop="infinite"/>
            </div>
        </div>);
    }
});
