'use strict';
/**
 * ========================================================
 * Description: Contains helper methods, mostly for communicating
 *              with server regarding entity requests
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/

function server_error_action(error, callback){
    error = error || {};
    var status = parseInt(error.status);
    var message= error.message;
    var error_JSON=error.responseJSON || {};
    var context = null;
    switch (status){
        case 500 :
            if(error_JSON.logged_in==false){
                context= new MainComponent;
                console.log(error_JSON);
                context.logout_invoke_error(message);
            } else {
                if ('function' === typeof callback)
                    callback(error_JSON);
                else{
                    context = new MainComponent;
                    context.invoke_error(error_JSON);
                }
            }
            break;
        case 502:
            var context= new MainComponent;
            console.log(error_JSON);
            //context.logout_invoke_error(message);
            break;
        case 504:
            var context= new MainComponent;
            console.log(error_JSON);
            //context.logout_invoke_error(message);
            break;

    }
}

function invoke_method(entity, method, data, success, error, complete, app_object_code) {
    var request = {};
    request.entity = entity;
    request.method = method;
    request.app_object_code = app_object_code;
    var org_id = get_parameter_by_name("org_id");
    if(org_id && (org_id != "")) {
        request.org_id = org_id;
    }
    request.data = data;
    return do_authenticated_http_call({
        method: "POST",
        url: "/api/entity/invoke_method",
        contentType: "application/json",
        dataType: 'json',
        data: JSON.stringify(request),
        success:success,
        error: function(err) {
            server_error_action(err, error);
        },
        complete: complete
    });
}
/*http://stackoverflow.com/questions/2144386/javascript-delete-cookie*/
function log_error(error) {
    var request = {};
    request.entity = "error";
    request.method = "log_error";
    request.data = {error:error};
    do_authenticated_http_call({
        method: "POST",
        url: "/api/entity/invoke_method",
        contentType: "application/json",
        dataType: 'json',
        data: JSON.stringify(request)
    });
}

function get_app_object(app_object_code, extra, success, error, complete) {
    if (!extra) {
        extra = {};
    }
    extra.app_object_code = app_object_code;
    invoke_method("app_object", "get_by_code", extra, function(data) { success(data.data); }, error, complete);
}

function make_conditions_from_devextreme_filter(filter_arr, field_paths, elemMatchDictionary) {
    //format1: [3]
    //[3] == att.field_path, "contains", filter_box.value
    //alternates for "contains":  "=", "<>", ">", ">=", "<", "<=", "startswith", "endswith", "contains", "notcontains"
    //format2: [[3], "and", [3], "or", [3], ...]
    var ret_val;

    ////////////////////////////////////////////////////////
    elemMatchDictionary = elemMatchDictionary || ((field_paths) ? {} : null); //  This is kinda hacky and requires some explanation as to its purpose:
    //
    //  This is for nested arrays, whose fields are now allowed to be searched in this manner.
    //  Searching these fields, when joined with an "and" doesn't work the way a user would intend
    //  Ex:  {$and: [{"xenforma_notes.note_type": "comment"}, {"xenforma_notes.author": "Adam"}]}
    //  Returns All records that have a note with a type of comment Intersected with all records that have a note with an author of Adam,
    //  Not, as a user would expect, all records that have a note which is of type comment by Adam.
    //  To get the desired behavior, we need to use $elemMatch.
    //  On all nested array fields (which will be tagged as .use_elem_match in the field's attributes) we must pull together each query on that field into a single query
    //  phrased as an elemMatch on the nested array field.
    //
    //  The elemMatchDictionary is meant to track such fields, and therefor only needs to exist if field_paths are specified so that we can check field_att.use_elem_match
    //  It is not designed to work more than a single layer deep, so the following style query could break it:  {$or: [{$and: [...]}, {$and: [...]}]}
    ////////////////////////////////////////////////////////



    ret_val = filter_arr[0];
    if(typeof ret_val == "string" || typeof ret_val == "function") {
        var temp = parse_condition_obj(filter_arr);
        ret_val = temp.condition;
        if (field_paths && field_paths[temp.field_path] && field_paths[temp.field_path].use_elem_match && (temp.field_path.indexOf(":") != -1)) {
            ret_val = {};
            var field_path_for_elem_match = temp.field_path.substring(0, temp.field_path.lastIndexOf(":"));
            var nested_field_path = temp.field_path;
            var condition_value = temp.sub_doc;
            nested_field_path = nested_field_path.substring(nested_field_path.lastIndexOf(":") + 1, nested_field_path.length);
            ret_val[nested_field_path] = condition_value;
            if (elemMatchDictionary[field_path_for_elem_match]) {
                //has already been added somewhere, push to this array and return an empty condition.
                elemMatchDictionary[field_path_for_elem_match][field_path_for_elem_match]["$elemMatch"]["$and"].push(ret_val);
                return {};
            }
            else {
                elemMatchDictionary[field_path_for_elem_match] = {}; //create as an $and to preserve reference integrity
                elemMatchDictionary[field_path_for_elem_match][field_path_for_elem_match] = {$elemMatch: {$and: [ret_val]}};
                ret_val = elemMatchDictionary[field_path_for_elem_match];
            }
        }
    } else if((Object.prototype.toString.call(ret_val) === '[object Array]') || (Object.prototype.toString.call(ret_val) === '[object Object]')) { //an array of conditions joined by and/or
        ret_val = make_conditions_from_devextreme_filter(filter_arr[0], field_paths, elemMatchDictionary);
        for (var i = 1; i < filter_arr.length; i++) {
            var condition = filter_arr[i];
            if (condition) {
                if (Object.prototype.toString.call(condition) === '[object Array]') {
                    //implies AND
                    var temp = parse_condition_obj(condition);
                    condition = temp.condition;
                    ret_val = add_and_condition(ret_val, condition, (field_paths && field_paths[temp.field_path] && field_paths[temp.field_path].use_elem_match && (temp.field_path.indexOf(":") != -1)) ? temp.field_path.substring(0, temp.field_path.lastIndexOf(":")) : false, elemMatchDictionary, temp.field_path, temp.sub_doc);
                }
                else if (typeof condition == "string") {
                    if (condition == "and") {
                        i++;
                        var condition_obj = filter_arr[i];
                        if ((typeof condition_obj == "object") && (Array.isArray(condition_obj)) && Array.isArray(condition_obj[0])) {
                            condition = make_conditions_from_devextreme_filter(condition_obj, field_paths, elemMatchDictionary);
                            ret_val = add_and_condition(ret_val, condition);
                        }
                        else
                        {
                            var temp = parse_condition_obj(condition_obj);
                            condition = temp.condition;
                            ret_val = add_and_condition(ret_val, condition, (field_paths && field_paths[temp.field_path] && field_paths[temp.field_path].use_elem_match && (temp.field_path.indexOf(":") != -1)) ? temp.field_path.substring(0, temp.field_path.lastIndexOf(":")) : false, elemMatchDictionary, temp.field_path, temp.sub_doc);
                        }
                    }
                    else if (condition == "or") {
                        i++;
                        var condition_obj = filter_arr[i];
                        if ((typeof condition_obj == "object") && (Array.isArray(condition_obj)) && condition_obj.length == 3 && Array.isArray(condition_obj[0])) {
                            condition = make_conditions_from_devextreme_filter(condition_obj, field_paths, elemMatchDictionary);
                        }
                        else
                        {
                            condition = parse_condition_obj(condition_obj).condition;
                        }
                        ret_val = add_or_condition(ret_val, condition);
                    }
                }
            }
        }
    }
    return ret_val;
}

function make_sorting_conditions_from_devextreme_sort(sort_arr) {
    var ret_val = {};

    for(var i = 0; i < sort_arr.length; i++) { //usually only 1
        var field_path = sort_arr[i].selector;
        var descending = sort_arr[i].desc;
        ret_val[field_path] = (descending)? -1 : 1;
    }

    return ret_val;
}

/**
 * A function for determining if devextreme sorting conditions changed from the last request
 * @param array_a
 * @param array_b
 * @returns {boolean}
 */
function devextreme_sorting_conditions_changed(array_a, array_b) {
    var a_is_valid = (array_a && Array.isArray(array_a));
    var b_is_valid = (array_b && Array.isArray(array_b));
    if(a_is_valid != b_is_valid) {
        return true;
    }
    if(!a_is_valid) {
        return false;
    }
    if(array_a.length != array_b.length) {
        return true;
    }
    for(var i = 0; i < array_a.length; i++) {
        var string_a = array_a[i];
        var string_b = array_b[i];
        var a_desc = false;
        var b_desc = false;
        if(string_a && (typeof string_a == "object")) {
            a_desc = string_a.desc;
            string_a = string_a.selector;
        }
        if(string_b && (typeof string_b == "object")) {
            b_desc = string_b.desc;
            string_b = string_b.selector;
        }
        if((string_a != string_b) || (a_desc != b_desc)) {
            return true;
        }
    }
    return false;
}

var filter_conditions_mongo_equivalent_lookup = {
    "=":"$eq",
    "<>": "$ne",
    ">": "$gt",
    ">=": "$gte",
    "<": "$lt",
    "<=": "$lte"//,
    //"contains": "$in",
    //"notcontains": "$nin",
    //"startswith": "",
    //"endswith": ""
};

function try_parse_regexp(value) {
    if(value && typeof value == "string") {
        var m = value.match(/\/(.*)\/(.*)?/);
        return new RegExp(m[1], m[2] || "");
    }
    return value;
}

function parse_condition_obj(condition_obj) {
    //[3] == att.field_path, "contains", filter_box.value
    //alternates for "contains":  "=", "<>", ">", ">=", "<", "<=", "startswith", "endswith", "contains", "notcontains"
    if (!condition_obj || (typeof condition_obj != "object") || (!Array.isArray(condition_obj)) || condition_obj.length !== 3) {
        return {};
    }
    var field_path = condition_obj[0];
    if (typeof field_path == "function")
    {
        field_path = field_path();
    }
    var operator = condition_obj[1];
    var comparison_value = condition_obj[2];

    var sub_doc = {};
    switch (operator) {
        /*case "=":
        {
            sub_doc[filter_conditions_mongo_equivalent_lookup[operator]] = comparison_value;
            break;
        }
        case "<>":
        {

            break;
        }
        case ">":
        {

            break;
        }
        case ">=":
        {

            break;
        }
        case "<":
        {

            break;
        }
        case "<=":
        {

            break;
        }*/
        case "startswith":
        {
            sub_doc["$regex"] = "^" + comparison_value.replace(/ /g, '\\s');
            sub_doc["$options"] = "i";
            break;
        }

        case "endswith":
        {
            sub_doc["$regex"] = comparison_value.replace(/ /g, '\\s') + "$";
            sub_doc["$options"] = "i";
            break;
        }
        case "contains":
        {
            sub_doc["$regex"] = comparison_value.replace(/ /g, '\\s');
            sub_doc["$options"] = "i";
            break;
        }
        case "notcontains":
        {
            sub_doc["$regex"] = comparison_value.replace(/ /g, '\\s');
            sub_doc["$options"] = "i";
            sub_doc = {
                "$not": sub_doc
            };
            break;
        }
        case "exactly":
        {
            sub_doc = comparison_value.replace(/ /g, '\\s');
            break;
        }
        default:
        {
            if(general_utils.object_is_date(comparison_value)) {
                //stringify it now to make sure it is stringified properly
                var m_date = new moment(comparison_value);
                comparison_value = m_date.format().slice(0, -6);
                comparison_value = comparison_value + ".000Z";
            }
            sub_doc[filter_conditions_mongo_equivalent_lookup[operator]] = comparison_value;
            break;
        }
    }

    var ret_val = {};
    ret_val[field_path] = sub_doc;
    return {field_path: field_path, condition: ret_val, sub_doc: sub_doc};
}

function add_and_condition(ret_val, condition, field_path_for_elem_match, elemMatchDictionary, nested_field_path, condition_value) {
    if (!condition) {
        return;
    }

    if (field_path_for_elem_match) {
        condition = {};
        nested_field_path = nested_field_path.substring(nested_field_path.lastIndexOf(":") + 1, nested_field_path.length);
        condition[nested_field_path] = condition_value;
        if (elemMatchDictionary[field_path_for_elem_match]) {
            //has already been added somewhere, update as a ref only.
            elemMatchDictionary[field_path_for_elem_match][field_path_for_elem_match]["$elemMatch"]["$and"].push(condition);
            return ret_val;
        }
        else {
            elemMatchDictionary[field_path_for_elem_match] = {}; //create as an $and to preserve reference integrity
            elemMatchDictionary[field_path_for_elem_match][field_path_for_elem_match] = {$elemMatch: {$and: [condition]}};
            condition = elemMatchDictionary[field_path_for_elem_match];
        }
    }

    if (!ret_val["$and"]) {
        var old_ret = ret_val;
        ret_val = {};
        ret_val["$and"] = [old_ret];
    }

    ret_val["$and"].push(condition);
    return ret_val;
}

function add_or_condition(ret_val, condition) {
    if(!condition) {
        return;
    }
    if(!ret_val["$or"]) {
        var old_ret = ret_val;
        ret_val = {};
        ret_val["$or"] = [old_ret];
    }
    ret_val["$or"].push(condition);
    return ret_val;
}

function format_string_date(date) {
    if(typeof date == 'string') {
        var year = date.slice(0,4);
        var month = date.slice(5,7) - 1;
        var day = date.slice(8,10);
        date = new Date(year,month,day,0,0,0,0);
    }
    return date;
}

function fix_request_data(data, field_paths, nested_list_entities) {
    for ( var x in data ) {
        if(data[x]) {
            //recursively call on objects
            if (typeof data[x] == 'object' && !(data[x] instanceof Date)) {
                if (field_paths && field_paths[x] && field_paths[x].db_type && nested_list_entities && nested_list_entities[field_paths[x].db_type]) {
                    data[x] = fix_request_data(data[x], nested_list_entities[field_paths[x].db_type].field_paths, nested_list_entities);
                }
                else {
                    data[x] = fix_request_data(data[x], field_paths, nested_list_entities);
                }
            }
            else if (field_paths && field_paths[x]) {
                var attribute = field_paths[x];

                //if data is date, convert to date string object
                if (typeof data[x] != "string") {
                    if (attribute.attribute_type == "Date") {
                        data[x] = new Date(Date.UTC(data[x].getFullYear(), data[x].getMonth(), data[x].getDate(), 0, 0, 0, 0)).toISOString();
                    } else if (attribute.attribute_type == "DateTime") {
                        data[x] = new Date(Date.UTC(data[x].getFullYear(), data[x].getMonth(), data[x].getDate(), data[x].getHours(), data[x].getMinutes(), data[x].getSeconds(), data[x].getMilliseconds())).toISOString();
                    } else if (attribute.attribute_type == "Time") {
                        data[x] = new Date(Date.UTC(0, 0, 0, data[x].getHours(), data[x].getMinutes(), data[x].getSeconds(), 0)).toISOString();
                    }
                }
            }
        }
    }

    return data;
}

function get_parameter(name) {
    name = name || '';
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("&" + name + "=([^&#]*)"),
        results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}
