/**
 * ============================================================
 * Description:     notes list view component
 * Creation Date:   12/27/16
 * Author:          Anthony C
 * ============================================================
 */

var NotesListComponent = React.createClass({
    debugging: false,
    tab: 0,
    debugLog: function (msg, start){
        if (this.debugging){
            if (!start) {
                this.tab--;
            }
            try{
                console.log((start?'-> ':'<- ') + Array(this.tab+1).join('\t') + msg + this.tab);
            } catch(e){console.log((start?'-> ':'<- ') + msg + this.tab);}
            if (start) {
                this.tab++;
            }
        }
    },
    propTypes: {
        app_object: React.PropTypes.object,
        app_object_handler: React.PropTypes.func,
        delete_nested_entity: React.PropTypes.func,
        entity_attributes: React.PropTypes.object,
        entity_instances: React.PropTypes.array,
        handle_executing: React.PropTypes.func,
        is_mobile: React.PropTypes.bool,
        is_open: React.PropTypes.bool,
        on_change: React.PropTypes.func,
        nested_entity_handler: React.PropTypes.func,
        nested_list_entities: React.PropTypes.object,
        parent_entity_array: React.PropTypes.array,
        parent_entity_field_attribute: React.PropTypes.object,
        parent_react_entity: React.PropTypes.object,
        read_only: React.PropTypes.bool,
        set_open_state: React.PropTypes.func
    },

    /**
     * Returns the custom data grid properties for the grid to be correctly initialized
     * @param args
     * @returns {*}
     */
    get_custom_grid_properties: function (args) {
        var context = this,
            custom_grid_properties = {};

        custom_grid_properties.force_ref_load = false;
        custom_grid_properties.paging = { enabled: false };
        custom_grid_properties.scrolling = { showScrollbar: 'none' };
        custom_grid_properties.headerFilter = { visible: true , text : "Comment" };
        custom_grid_properties.showColumnHeaders = false;
        custom_grid_properties.filterRow = { visible: true };
        custom_grid_properties.searchPanel = { visible: false };
        custom_grid_properties.export = { enabled: false };
        custom_grid_properties.columnChooser = { enabled: false };
        custom_grid_properties.rowTemplate = context.get_custom_note_row_template(args.grid_id, args.custom_properties, args.columns);
        custom_grid_properties.onEditorPrepared = context.onEditorPrepared;

        return custom_grid_properties;
    },

    onEditorPrepared: function(e) {
        try { //just in case the Editor is not actually ready.
            var selectBox = e.editorElement.dxSelectBox('instance');
            if ( selectBox ) {
                selectBox.option('searchEnabled', false);
            }
        } catch(er) {}
    },

    /**
     * Returns the row template for the notes
     * @param grid_id
     * @param custom_properties
     * @param columns
     * @returns {*}
     */
    get_custom_note_row_template: function (grid_id, custom_properties, columns) {
        var context = this;
        context.debugLog('get_custom_note_row_template', true);

        if ( columns ) {
            context.remove_command_columns(columns);

            return (
                '<tbody><tr><td colspan="' + columns.length + '">' +
                '<div class="comment-body">' +
                '<div className="row" >' +
                '<table class="table" style="width:100%;" >' +
                '<tr><td width="40" valign="center" align="center">' +
                '<a title="">' + '{{html DataListUtils.note_template_format_image}}' + '</a>' +
                '</td><td><div style="margin-left:0px;padding:0px;" class="comment" >' + '<div class="comment-text">' +
                '<div class="comment-header">' +
                '<a title="">' + "${DataListUtils.note_template_format_author}" + '</a><span>' + "${DataListUtils.note_template_format_date}" + '</span>' +
                '</div>' +
                '<i style="margin-right: 5px;" class="fa' + "${data.note_type}" + '"></i>' +
                '<b>' + "${data.subject}" + '</b>' +
                '<div>{{html data.content}}</div>' +
                '</div></div>' +
                '<div style="margin-left:2px; margin-top:5px;" class="comment-footer" note_type="${data.note_type}" >'
                + ((custom_properties.editable) ? ('<a href="javascript:void(0)" onclick="' + "$('#" + grid_id + "').dxDataGrid('instance').editRow(${rowIndex})" + '" style="color:#d0d0d0;font-size:10px;" >${DataListUtils.note_template_format_edit_link}</a>'
                + ((!custom_properties.disable_delete) ? '&nbsp;<a href="javascript:void(0)" onclick="' + "$('#" + grid_id + "').dxDataGrid('instance').deleteRow(${rowIndex})" + '" style="color:#d0d0d0;font-size:10px;">${DataListUtils.note_template_format_delete_link}</a>'
                    : ''))
                    : '') +
                '</div>' +
                '' +
                '</td></tr></table>' +
                '</div>' +
                '</div></td></tr></tbody>'
            );
        }

        context.debugLog('get_custom_note_row_template', false);
        return undefined;
    },

    /**
     * Remove the command columns from the columns list
     * @param columns
     */
    remove_command_columns: function (columns) {
        var context = this;
        context.debugLog("remove_command_columns", true);

        if ( columns[0] && columns[0].name == "controls" ) {
            columns.shift();//remove first column, as it is the command column
        }
        else if ( columns[columns.length-1] && columns[columns.length-1].name == "controls" ) {
            columns.pop();//remove last column, as it is the command column
        }

        context.debugLog("remove_command_columns", false);
    },

    render: function() {
        var context = this;

        context.debugLog("render", true);
        context.debugLog("render", false);
        return (
            <div className="Notescls">
                <DataListComponent app_object={context.props.app_object}
                                   app_object_handler={context.props.app_object_handler}
                                   delete_nested_entity={context.props.delete_nested_entity}
                                   entity_attributes={context.props.entity_attributes}
                                   entity_instances={context.props.entity_instances}
                                   get_custom_grid_properties={context.get_custom_grid_properties}
                                   grid_is_on_edit_form={true}
                                   handle_executing={context.props.handle_executing}
                                   is_mobile={context.props.is_mobile}
                                   is_nested={true}
                                   is_open={context.props.is_open}
                                   is_xenforma_note={true}
                                   on_change={context.props.on_change}
                                   nested_entity_handler={context.props.nested_entity_handler}
                                   nested_list_entities={context.props.nested_list_entities}
                                   parent_entity_array={context.props.parent_entity_array}
                                   parent_entity_field_attribute={context.props.parent_entity_field_attribute}
                                   parent_react_entity={context.props.parent_react_entity}
                                   read_only={context.props.read_only}
                                   set_open_state={context.props.set_open_state} />
            </div>
        );
    }
});
