/**
 * ========================================================
 * Description: A web control for a multi-line textbox
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/
var EditorDevExtremeMultiLineTextBox = React.createClass({
    getInitialState: function () {
        return {};
    },
    componentWillReceiveProps: function (nextProps) {
        if (nextProps.value != this.state.value) {
            this.state.value = nextProps.value;
            this.state.element.dxTextArea('instance').option("value", this.state.value);
        }
    },
    componentDidMount:function()
    {
        var context = this;
        this.state.value = this.props.value;
        this.state.element = $(this.domElement).dxTextArea({
            height: this.props.height,
            width: this.props.width,
            readOnly: this.props.readOnly,
            value: this.state.value,
            placeholder: this.props.placeholder,
            onValueChanged: function(data) {
                context.state.value = data.value;
                context.props.onChange(context.state.value);
            },
            onFocusOut: function(e){
                context.props.onBlur();
            }
        });
    },
    render: function() {
        var context = this;
        return <div ref={function(ref){context.domElement = ref}} />;
    }
});
