/**
 * ========================================================
 * Description: Renders the top bar of the web app
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/
var NavBarComponent = React.createClass({
    getInitialState: function () {
        return {};
    },
    handle_setting_click: function(event) {
        event.preventDefault();
        this.props.navigation_handler("settings");
    },
    handle_billing_click: function(event) {
        event.preventDefault();
        this.props.navigation_handler("billing_settings");
    },
    handle_logout_click: function(event) {
        event.preventDefault();
        push_href_location("" , "/");
        this.props.navigation_handler("logout");
    },
    sidebar_click:function(){
        var button = $(".sidebar-collapse");
            button.toggleClass("active");
            this.props.menu_click(button.hasClass("active"));
    },
    componentWillReceiveProps: function (nextProps){
        var org_id = get_parameter('org_id');
        if(org_id) {
            this.set_organization_by_url(org_id, nextProps);
        }
    },
    set_organization_by_url:function(org_id, props){
        if (!this.get_org) {
            if (org_id && props.user_info){
                if (org_id != props.user_info.current_organization){
                    this.get_org = true;
                    props.user_info.current_organization = org_id;
                    this.on_field_change({target:{value:org_id}, from_url:true});
                }
            }
        }
    },
    componentDidMount: function(){
        var org_id = get_parameter('org_id');
        if (org_id) {
            this.set_organization_by_url(org_id, this.props);
        }
    },
    componentDidUpdate: function(){
        var org_select = $("#organization-dropdown>select");
        if (this.props && this.props.user_info && this.props.user_info.current_organization){
            if (this.props.user_info.current_organization != org_select.val()){
                org_select.val(this.props.user_info.current_organization);
            }
        }
    },
    on_field_change: function(event){
        this.setState({loading: true});

        var request = {};
        request.entity = "user";
        request.method = "update_info";
        request.data = {};
        request.data.current_organization = event.target.value;

        var context = this;

        do_authenticated_http_call({
            method: "POST",
            url: "/api/entity/invoke_method",
            contentType: "application/json",
            dataType: 'json',
            data: JSON.stringify(request),
            success: function (data) {
                var login_token=localStorage.getItem("login_token");
                if(!event.from_url) Notify(data.message, 'bottom-right', notify_timeout, 'green', 'fa-check', true);
                $("ul.account-area").children().toggleClass('open');
                localStorage.removeItem("get_info");
                localStorage.clear();
                localStorage.setItem("login_token", login_token);
                if(!event.from_url) location.reload();

            },
            error: function (error) {
                server_error_action(error);
                if (error.responseJSON) {
                    console.log(error.responseJSON);
                    context.setState({error: error.responseJSON.message});
                }
            },
            complete: function () {

                context.setState({loading: false, update: {}});
            }
        });

    },
    select_click: function(){
        $("ul.account-area").children().toggleClass('open');
    },
    render: function() {
        var first_name = "";
        var last_name = "";
        var email_address = "";
        var billing = false;
        var organizations="";
        var organization_dropdown='';
        if (this.props.user_info) {
            first_name = this.props.user_info.first_name;
            last_name = this.props.user_info.last_name;
            email_address = this.props.user_info.email_address;
            billing = this.props.billing;
            organizations=this.props.user_info.organizations;

            if(organizations.length){
                var options= [];
                if(organizations.length > 1){
                    for(var i=0; i < organizations.length; i++){
                        var option=<option value={organizations[i].entity_id} key={organizations[i].entity_id} data-html="true" >{organizations[i].caption}</option>;
                        options.push(option);
                    }

                    organization_dropdown= <div id="organization-dropdown" onClick={this.select_click}><select defaultValue={this.props.user_info.current_organization} onChange={this.on_field_change} className="form-control">{options}</select></div>;

                }
            }
        }

        var avatar;
        if (this.props.user_avatar)
        {
            avatar = this.props.user_avatar;
        }
        var avatar_element;

        if(avatar){
            avatar_element = <div style={{
                                        width : "35px",
                                         height : "35px",
                                         backgroundImage:  "url("+avatar+")",
                                         backgroundRepeat : "no-repeat",
                                         backgroundPosition : "center center",
                                         backgroundSize : "cover",
                                         marginRight : "2px",
                                         display:'inline-block'
                                          }}> </div>

        }else{
            avatar_element = <i className="fa fa-user" aria-hidden="true" style={{ marginTop : "0px" , fontSize : "35px" , color : "white"   }}>&nbsp;</i>;
        }
        if(!this.props.is_mobile) {
            var hamburger_menu = (<div className="sidebar-collapse" id="sidebar-collapse"onClick={this.sidebar_click}>
                <i className="collapse-icon fa fa-bars"  style={{ marginRight : "15px"}}  />
            </div>);
        }
        return (
            <div className="navbar">
                <div className="navbar-inner">
                    <div className="navbar-container">
                        <div className="navbar-header pull-left">
                            <a href="/" className="navbar-brand">
                                <small>
                                    <img src="images/logo.png" alt="" />
                                </small>
                            </a>
                        </div>
                        {hamburger_menu}
                        <div className="navbar-header pull-right">
                            <div className="navbar-account" align="center">
                                <ul className="account-area" style={{padding: "0px" , margin:"0px"}}>
                                    <li >
                                        <a className="login-area dropdown-toggle" data-toggle="dropdown" style={{ textAlign : "center" ,padding: "0px" , margin:"0px"}}>

                                            <div className="avatar" title="View your public profile" style={{width: '100%' , height:'100%'}}>
                                                    <table width="100%" height="100%">
                                                        <tbody>
                                                            <tr>
                                                                <td valign="center" align="center" style={{textAlign: "center" , verticalAlign:"center" , paddingTop : "3px" }} >
                                                                    <div align="center" >
                                                                        { avatar_element }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                        </a>
                                        <ul className="pull-right dropdown-menu dropdown-arrow dropdown-login-area">
                                            <li className="email">
                                                <table style={{width:"100%"}}>
                                                    <tbody>
                                                    <tr>
                                                        <td colSpan="5">
                                                            <span className="menu-user-name">{first_name + " " + last_name}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="5">
                                                            <mark style={{backgroundColor : "transparent" }} className="text-primary" href="javascript:void(0)">{email_address}</mark>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td >
                                                            <div>
                                                                {organization_dropdown}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </li>
                                            <li className="dropdown-footer">
                                                <table style={{width:"100%"}}>
                                                    <tbody>
                                                    <tr>
                                                        <td style={{textAlign : "left"}}>
                                                            <a id="settings-button" href="" className='fa fa-cog' onClick={this.handle_setting_click}>&nbsp;</a>
                                                            {billing ? <a id="billing-button" href="" style={{marginLeft:"10px"}} className='fa fa-credit-card' onClick={this.handle_billing_click}>&nbsp;</a> : ''}
                                                        </td>
                                                        <td>
                                                            <a id="logout-button" href="" className='fa fa-power-off' onClick={this.handle_logout_click}>&nbsp;</a>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
});
