/**
 * ========================================================
 * Description: Renders the setup user page & interface (pre-login)
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/
var SetupUserComponent = React.createClass({
    getInitialState: function () {
        this.get_secret_questions();
        return {error: undefined};
    },
    componentDidMount: function(){
        this.set_suggested_username();
        $('#username').focus();
    },
    get_secret_questions: function () {
        var context = this;

        $.ajax({
            method: "GET",
            url: "/api/auth/get_secret_questions",
            success: function (data) {
                context.setState({secret_questions: data});
            },
            error: function (err) {
                console.log(err);
            }
        })
    },
    handle_submit: function (event) {
        event.preventDefault();

        if (!this.validate_password()) {
            element = $('#password');
            element.popover({content: R.password_complexity, trigger:"manual focus", placement:"bottom"});
            element.popover('show');
            element.focus();
            return false;
        }
        if(!this.validate_confirmed_password()){
            element = $('#confirm-password');
            element.popover({content: R.message_passwords_dont_match, trigger:"manual focus", placement:"bottom"});
            element.popover('show');
            element.focus();
            return false;
        }

        var request = {};
        request.setup_token = get_parameter_by_name("setup_token");
        request.username = this.state.username;
        request.password = this.state.password;
        request.secret_question = this.state.secret_question;
        request.secret_answer = this.state.secret_answer;

        this.setState({loading: true});

        var context = this;
        $.post("/api/auth/setup_user", request, function (data) {
            if (data) {
                if (data.success) {
                    context.setState({completed: true});
                    var login_token = data.login_token;
                    if (login_token !== undefined) {
                        localStorage.setItem("login_token", login_token);
                        g_login_token = login_token;

                        context.setState({success: true});
                        //return;
                    }else{
                        // we did not receive a login_token
                    }
                    Notify(R.message_successfully_registered, 'bottom-right', notify_timeout, 'green', 'fa-check', true);
                    context.props.on_success();
                }
                else {
                    context.setState({error: data.message});
                }

            }
        }).fail(function (error) {
            error = error.responseJSON;
            console.log(error);
            context.setState({error: error.message});
        }).complete(function () {
            if (!context.state.completed) {
                context.setState({loading: false});
            }
        });
    },
    on_username_change: function (event) {
        this.setState({username: event.target.value});
        $("#username").bootstrapValidator();
    },
    on_username_blur : function(){
        this.set_suggested_username();
    },

    on_password_change: function (event) {
        this.state.password = event.target.value;
        var element = $(event.target).parent();
        this.set_validated_class(element, this.validate_password() && event.target.value);
        this.validate_confirmed_password();
        this.forceUpdate();
    },
    on_confirmed_password_change: function (event) {
        this.state.confirmed_password = event.target.value;
        this.validate_confirmed_password();
        this.forceUpdate();
    },
    on_secret_question_change: function (event) {
        this.setState({secret_question: event.target.value});
    },
    on_secret_answer_change: function (event) {
        this.setState({secret_answer: event.target.value});
    },
    remove_validated_class: function(element){
        element.removeClass('has-success');
        element.removeClass('has-error');
    },
    set_validated_class: function(element, valid){
        if(valid){
            element.addClass('has-success');
            element.removeClass('has-error');
        } else {
            element.removeClass('has-success');
            element.addClass('has-error');
        }
    },
    validate_password: function (){
        if(1>=this.password_strength()){
            var element = $('#password');
            try{
                element.popover('destroy');
            }catch(e){}
            return true;
        }else{
            return false;
        }
    },
    password_strength: function(){
        var pw = this.state.password;
        //calculate password score
        var score = 0;
        if (pw){
            //length
            score += pw.length>=8?0:100;
            //Uppercase letters
            score += pw.replace(/[^A-Z]/g, "").length?0:1;
            //Lowercase letters
            score += pw.replace(/[^a-z]/g, "").length?0:1;
            //numbers
            score += pw.replace(/[\D]/g, "").length?0:1;
            //symbols
            score += pw.replace(/[\w]/g, "").length?0:1;
        }
        return score;
    },
    validate_confirmed_password: function (){
        var element = $('#confirm-password');
        var valid = (this.state.confirmed_password == this.state.password) && this.state.confirmed_password && this.validate_password();
        this.set_validated_class(element.parent(), valid);
        if (valid) {
            try {
                element.popover('destroy');
            } catch (e) {}
        }
        return valid;
    },
    build_username: function(callback){
        var request = {data:{}};
        request.data.login_name = this.state.username;
        request.data.setup_token = get_parameter_by_name('setup_token');
        $.ajax({
            method: "POST",
            url: "/api/auth/get_suggested_login_name",
            dataType: "json",
            contentType:"application/json",
            data: JSON.stringify(request),
            success: function (data) {
                callback(data.data);
            },
            error: function (err) {
                console.log(err);
            }
        });
    },
    select_suggested_username: function(un){
        $('#username').val(un.target.text);
        this.setState({username: un.target.text, suggested_username: undefined, alternate_username: undefined});
        this.on_username_blur();
        $('#password').focus();
    },
    set_suggested_username: function(){
        var context = this;
        context.build_username(function (data) {
            if (context.state.username == data.suggested_username || context.state.username == data.alternate_username)
                context.setState({
                    suggested_username: undefined,
                    alternate_username: undefined
                });
            else context.setState(data);
        });
    },

    componentWillUnmount: function () {
        document.body.className = "";
    },
    render: function () {
        document.body.className = "register-body";
        var error_component = "";
        if (this.state.error) {
            var on_close = function (context) {
                return function () {
                    context.setState({error: undefined});
                };
            }(this);
            error_component = <ErrorNotificationComponent message={this.state.error} on_close={on_close}/>;
        }

        var secret_questions = [];
        if (this.state.secret_questions) {
            for (var i = 0; i < this.state.secret_questions.length; i++) {
                var secret_question = this.state.secret_questions[i];
                secret_questions.push(<option key={secret_question.code}
                                              value={secret_question.code}>{secret_question.value}</option>);
            }
        }

        var submit_button = <input type="submit" className="btn btn-primary btn-block" value={R.label_submit}/>;
        if (this.state.loading) {
            submit_button =
                <input type="submit" disabled className="btn btn-primary btn-block" value={R.label_submit}/>;
        }

        var taken='';
        var available= '';
        if(this.state.suggested_username){
            var alternate = '';
            if(this.state.alternate_username && this.state.alternate_username!=this.state.suggested_username){
                alternate = <a href="javaScript:void(0)" onClick={this.select_suggested_username}>{this.state.alternate_username}</a>;
            }
            available = <i>{R.available_username}: <a href="javaScript:void(0)" onClick={this.select_suggested_username}>{this.state.suggested_username}</a>{alternate?', ':''}{alternate}</i>;
        }

        return (<div className="register-container animated fadeInDown">
            <table className="registerbox">
                <tbody>
                <tr>
                    <td>&nbsp;</td>
                    <td>
                        <div className="registerbox-header01">
                            <div className="registerbox-header02">
                                <div style={{"textAlign":"center"}}>
                                    <img src="images/logo.png" alt=""/>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td className="registerbox-menu-border-ribbon">&nbsp;</td>
                    <td>
                        <div className="registerbox-menu_right">
                            <div className="registerbox-loggedout_right">
                                {R.label_register}
                            </div>
                        </div>
                    </td>
                    <td className="registerbox-menu-border-ribbon">&nbsp;</td>
                </tr>
                <tr>
                    <td className="registerbox-menu-left-ribbon">&nbsp;</td>
                    <td>
                        <div style={{"position":"relative"}}>
                            <div className="registerbox-ribbon-shadow"></div>
                            <div className="registerbox-content01">
                                <div className="registerbox-content02">
                                    <form className="bv-form" onSubmit={this.handle_submit}>
                                        {error_component}
                                        {R.label_fill_in_info}
                                        <br/>
                                        <br/>
                                        <div className="registerbox-textbox">
                                            <input id="username"
                                                   type="text"
                                                   className="form-control"
                                                   placeholder={R.client_label_fill_in_info || 'Create a user name that you will use to login to the application'}
                                                   onChange={this.on_username_change}
                                                   onBlur={this.on_username_blur}
                                            />
                                            {available}
                                        </div>
                                        <div className="registerbox-textbox">
                                            <input id="password"
                                                   type="password"
                                                   className="form-control"
                                                   placeholder={R.placeholder_password}
                                                   onChange={this.on_password_change}/>
                                        </div>
                                        <div className="registerbox-textbox">
                                            <input id="confirm-password"
                                                   type="password"
                                                   className="form-control"
                                                   placeholder={R.placeholder_confirm_password}
                                                   onChange={this.on_confirmed_password_change}/>
                                        </div>
                                        <div className="registerbox-textbox">
                                            <select className="form-control" name="country" defaultValue=""
                                                    onChange={this.on_secret_question_change}>
                                                <option disabled key="" value="">{R.select_secret_question}</option>
                                                {secret_questions}
                                            </select>
                                        </div>
                                        <div className="registerbox-textbox">
                                            <input type="password" className="form-control"
                                                   placeholder={R.placeholder_secret_answer}
                                                   onChange={this.on_secret_answer_change}/>
                                        </div>
                                        <div className="registerbox-submit">
                                            {submit_button}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td className="registerbox-menu-right-ribbon"></td>
                </tr>
                </tbody>
            </table>
        </div>);
    }
});
