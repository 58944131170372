/**
 * ============================================================
 * Description:     Data list common utils
 * Creation Date:   11/29/16
 * Author:          Anthony C
 * ============================================================
 **/

var DataListUtils = {
    debugging: false,
    tab: 0,
    debugLog: function (msg, start){
        if (this.debugging){
            if (!start) {
                this.tab--;
            }
            try{
                console.log((start?'-> ':'<- ') + Array(this.tab+1).join('\t') + msg + this.tab);
            } catch(e){console.log((start?'-> ':'<- ') + msg + this.tab);}
            if (start) {
                this.tab++;
            }
        }
    },


    /**
     * Returns the height for the data list component
     * @param grid_is_on_edit_form
     * @param is_mobile
     * @param skip_location_push
     * @param selectedViewsetting
     * @param variable_offset - A number, in px, to subtract from height (to leave room for whatever)
     * @param return_as_number
     * @returns {*}
     */
    update_height: function(grid_is_on_edit_form, is_mobile, skip_location_push, selectedViewsetting, variable_offset, return_as_number){
        this.debugLog('update_height', true);
        var retval = undefined;

        if ( !grid_is_on_edit_form ) {
            var height_adjustment = this.getHeightAdjustment(is_mobile, skip_location_push, selectedViewsetting);
            var button_container = $('#button-container'),
                description_grid = $(".description_grid");
            if ( description_grid.height() == null ) {
                retval = $(window).height() - 180 + height_adjustment;
            } else {
                var heightDescription = description_grid.height() + 10;
                if ( description_grid.css("display") == "none" ) {
                    heightDescription = 0;
                }
                var heightButtons = button_container.height() + 17;
                if ( !button_container.is(':visible') ) {
                    heightButtons = 0;
                }
                retval = $(window).height() - 130 - heightDescription - heightButtons + height_adjustment;
            }
        }

        if ( retval ) {
            if(variable_offset) {
                retval = retval - variable_offset;
            }
            if(retval < 30) {
                retval = 30; //Arbitrary minimum
            }
            if(!return_as_number) {
                retval = "" + retval + "px";
            }
        }

        this.debugLog('update_height', false);
        return retval;
    },
    /**
     * Returns the height adjustment depending on the component that is to be rendered
     * @param is_mobile
     * @param skip_location_push
     * @param selectedViewsetting
     * @returns {*}
     */
    getHeightAdjustment: function(is_mobile, skip_location_push, selectedViewsetting) {
        var height_adjustment;
        if ( is_mobile && !skip_location_push ) {
            height_adjustment = 57;
        }
        else {
            height_adjustment = 0;
        }
        switch ( selectedViewsetting ) {
            case 'data_list':
                break;
            case 'tree_view':
                break;
            case 'map_view':
                break;
            case 'calendar_view':
                height_adjustment += -20;
                break;
        }

        return height_adjustment;
    },

    /**
     * Returns the local storage personalized layout code which will differ depending on if we are using a filter or no.
     * @param is_grid_switched_to_mobile_view_yet
     * @param app_object_code
     * @param columns_hidden_for_mobile_view
     * @param conditions
     * @returns {string}
     */
    getLocalStoragePersonalizedLayoutCode: function(is_grid_switched_to_mobile_view_yet, app_object_code, columns_hidden_for_mobile_view, conditions) {
        return "personalized_layout_" + this.getDbStoragePersonalizedLayoutCode(is_grid_switched_to_mobile_view_yet, app_object_code, columns_hidden_for_mobile_view, conditions);
    },
    /**
     * Returns the storage personalized layout code used for saving to the database
     * @param is_grid_switched_to_mobile_view_yet
     * @param app_object_code
     * @param columns_hidden_for_mobile_view
     * @param conditions
     * @returns {string}
     */
    getDbStoragePersonalizedLayoutCode: function(is_grid_switched_to_mobile_view_yet, app_object_code, columns_hidden_for_mobile_view, conditions) {
        var status = this.getWorkflowStatus(conditions);
        return make_personalized_storage_code(app_object_code, status, (is_grid_switched_to_mobile_view_yet || columns_hidden_for_mobile_view));
    },
    /**
     * Returns the workflow status, if there is one (in the case we are using a filter)
     * @param conditions
     * @returns {*|string}
     */
    getWorkflowStatus: function(conditions) {
        return conditions ? conditions.workflow_status : undefined;
    },

    //////////
    // NOTE //
    //////////
    note_template_format_date: function () {
        if ( Date.now() > moment(this.data.data.date_created).toDate() ) {
            return moment(this.data.data.date_created).fromNow();
        }
        return moment(Date.now()).from(Date.now());
    },
    note_template_format_image: function () {
        if ( this.data.data.author && this.data.data.author.image ) {
            return "<div style='background-image:url(" + this.data.data.author.image + ");background-repeat:none;background-position:center center;width:35px;height:35px;background-size:cover;' ></div>";
        }
        return  '<i class="fa fa-user" aria-hidden="true" style="margin-top:4px;font-size:27px;color:gray;" ></i>';
    },
    note_template_format_author: function () {
        if ( this.data.data.author && this.data.data.author.caption ) {
            return this.data.data.author.caption;
        }
        return '';
    },
    note_template_format_edit_link: function () {
        if ( this.data.data.author && this.data.data.author.entity_id && (user_id == this.data.data.author.entity_id) ) {
            return R.edit;
        }
        return '';
    },
    note_template_format_delete_link: function () {
        if ( this.data.data.author && this.data.data.author.entity_id && (user_id == this.data.data.author.entity_id) ) {
            return R.label_delete;
        }
        return '';
    }
};