"use strict";
/**
 ==================================================================================
 Description:       dx Lookup field
 Creation Date:     4/27/2016
 Author:            Sebastian Mansfield
 ==================================================================================
 */

var EditorDevExtremeLookup = React.createClass({
    propTypes: {
        currentInstance: React.PropTypes.array,
        dataSource: React.PropTypes.object,
        displayExpr: React.PropTypes.string,
        id: React.PropTypes.string,
        is_in_grid_edit: React.PropTypes.bool,
        onBlur: React.PropTypes.func,
        onChange: React.PropTypes.func,
        onDisposing: React.PropTypes.func,
        placeholder: React.PropTypes.string,
        readOnly: React.PropTypes.bool,
        set_next_focus_element: React.PropTypes.func,
        skip_prop_update_function: React.PropTypes.func,
        value: React.PropTypes.string,
        valueExpr: React.PropTypes.string,
    },
    getInitialState: function () {
        return {
            has_pressed_any_key: !(this.props && this.props.is_in_grid_edit)
        };
    },
    componentWillReceiveProps: function (nextProps) {
        if (this.state.element && !this.props.skip_prop_update_function) {
            try{
                var element = this.state.element;
                if (nextProps.value != this.state.value) {
                    this.state.value = nextProps.value;
                    element.option("value", nextProps.value);
                } else {
                    element.repaint();
                }
            } catch(e){
                console.log(e);
            }
        }
    },
    componentDidMount: function () {
        var context = this;
        context.val = 0;
        context.selection = 0;
        this.state.value = this.props.value;
        this.state.element = $(this.domElement).dxSelectBox({
            readOnly: this.props.readOnly,
            value: this.state.value,
            displayExpr: this.props.displayExpr,
            valueExpr: this.props.valueExpr,
            dataSource: this.props.dataSource,
            placeholder: this.props.placeholder,
            minSearchLength :1,
            searchEnabled: true,
            showClearButton: true,
            showDropButton:false,
            showDataBeforeSearch:false,
            deferRendering:true,
            searchTimeout:250,
            onDisposing: this.props.onDisposing,
            onContentReady : autosize_dropdown_list_width_by_options,
            onKeyDown: function(args){
                context.state.has_pressed_any_key = true;
                if (!context.props.skip_prop_update_function) {
                    var event = args.jQueryEvent,
                        list = $(args.component._$list).find('.dx-scrollview-content').children();
                    if ((event.key !== "Tab" && event.key !== "Enter") || context.props.is_in_grid_edit) {
                        return;
                    }

                    var selectBox = args.component;
                    var items = selectBox.option("items");
                    if (items.length && selectBox.option("text") && selectBox.option("text") !== selectBox.option("value")) {
                        for (var i = 0, u = list.length; i < u; i++) {
                            if (list[i].id) {
                                if (items[i][context.props.valueExpr] != selectBox.option('value')) {
                                    selectBox.option("value", items[i][context.props.valueExpr]);
                                    console.log(items[i]);
                                    return;
                                }
                            }
                        }
                        if (!selectBox.option("value") && list.length && (items[0][context.props.valueExpr] != null)) {
                            selectBox.option("value", items[0][context.props.valueExpr]);
                        }
                    }
                    if ((event.key == "Enter") && context.props.set_next_focus_element) {
                        context.props.set_next_focus_element();
                    }
                }
            },
            itemTemplate: function(itemData) {
                var searchString = context.state.element.option('text');
                if(searchString) {
                    searchString = searchString.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
                }
                else {
                    searchString = "";
                }
                return itemData[context.props.displayExpr]?itemData[context.props.displayExpr].replace(new RegExp(searchString, 'gi'), '<b>' + searchString + '</b>'):'';
            },
            onValueChanged: function (data) {
                if (data.value == "")
                    console.log(data);
                var value = data.value;
                context.state.value = value;
                var dataValue = {};
                if (typeof data.selectedItem != "undefined" && data.selectedItem != null) {
                    dataValue = data.selectedItem;
                }
                if (context.props.currentInstance) {
                    var currentInstance = context.props.currentInstance;
                    for (var i = 0; i < currentInstance.length; i++) {
                        if (currentInstance[i].entity_id == value) {
                            if (currentInstance[i].entity_name) {
                                dataValue["entity_name"] = currentInstance[i].entity_name;
                                break;
                            }
                        }
                    }
                }
                if (value == null) {
                    if(context.state.has_pressed_any_key) {
                        context.props.onChange(null);
                    }
                }
                else {
                    context.state.has_pressed_any_key = true;
                    dataValue[context.props.valueExpr] = value;
                    if (!dataValue) {
                        this.dataSource.searchValue(value);
                        this.dataSource.searchExpr(data.valueExpr);
                        this.dataSource.searchOperation('exactly');
                        this.dataSource.load().done(function (dsData) {
                            var valueObject = find_by_field(data.valueExpr, value, dsData);
                            if (valueObject) {
                                data.itemData = valueObject;
                                data.selectedItem = valueObject;
                                context.props.onChange(valueObject);
                            }
                        })
                    } else {
                        if (dataValue.code) {
                            context.props.onChange(dataValue.code);
                        }
                        else {
                            context.props.onChange(dataValue);
                        }

                    }
                }

                if ('function' == typeof context.props.onBlur){
                    context.props.onBlur(data);
                }
                context.state.element.focus();
            }
        }).dxSelectBox('instance');

        if (this.props.id) {
            $(this.domElement).find("input").attr("id", this.props.id);
        }
    },
    render: function () {
        var context = this;
        var className = "";

        if (this.props.add_button) {
            className = "add-button-margin";
        } else {
            if (this.props.edit_button) {
                className = "add-less-button-margin";
            }
        }

        return (
            <input class="form-control"/>,
            <div
                id='dxSelectBox'
                className={className}
                ref={function(ref) { context.domElement = ref }}
            />
        );
    }
});
