/**
 * ========================================================
 * Description: A web control for editing a text field which
 *              can contain html
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/
var EditorHtml = React.createClass({
    render: function() {
        return <div ref="domElement" />;
    },
    componentDidMount:function()
    {
        var context = this;
        context.oldValue = this.props.value;
        $(this.refs.domElement).html(this.props.value).tinymce({
            readonly:this.props.readOnly,
            toolbar: 'undo redo | styleselect | bold italic | link image',
            menubar: false,
            setup : function(ed){
                //Please remove this code if the below 'blur' code work properly
                /*ed.on('NodeChange', function(e){
                    var content = ed.getContent();
                    if (context.oldValue == content || (context.oldValue == undefined && content == "")) {
                        context.oldValue = content;
                        return;
                    }
                    context.oldValue = content;
                    context.props.onChange(content);
                });*/
                ed.on('blur', function(e){
                    var content = ed.getContent();
                    if (context.oldValue == content) {
                        return;
                    }
                    context.oldValue = content;
                    context.props.onChange(content);
                    context.props.onBlur();
                });
            }
        });
    }
});
